import React, { useContext, useEffect, useState } from "react";
import { BiArrowToTop } from "react-icons/bi";
import { useHistory, useLocation } from "react-router-dom";
import {
  createCart,
  getAllAddress,
  getAppDesignData,
  getCart,
  getDemoAppDesignData,
  getDetailsByCoords,
  getInaamPoints,
  getMainCategories,
  getProductFromSku,
  getWishList,
  getZoneById,
  updateAddress,
} from "./api";
import "./App.css";
import { AddressDetails } from "./components/AddressDetails/AddressDetails";
import Breadcrumbs from "./components/Breadcrumbs";
import Cookies from "./components/Cookies";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import LoginPopup from "./components/LoginPopup";
import MainLoader from "./components/MainLoader";
import ToastMessage from "./components/ToastMessage";
import locale from "./constants/locale";
import { AppContext } from "./context/AppContext";
import { FlagsContext } from "./context/FlagsProvider";
import { DownloadApp } from "./pages/DownloadApp/DownloadApp";
import InaamDownload from "./pages/Inaam/InaamDownload";
import Location from "./pages/Location";
import NestoApp from "./pages/NestoApp/NestoApp";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import SiteUnderMaintenance from "./pages/SiteUnderMaintenance";
import Routes from "./routes/Routes";
import { makeAddressData } from "./utils/makeAddressData";

const App = () => {
  const {
    setHomePageOrder,
    isLoggedIn,
    initializeFavourites,
    setShowToast,
    setToastData,
    updateCategory,
    currentStore,
    logout,
    initializeCart,
    cartUpdated,
    wishListUpdated,
    setInaamData,
    profile,
    setCouponApplied,
    setAppDesignData,
    setIsDemo,
    store,
    defaultLocale,
    setDefaultLocale,
    setUserSavedCards,
  } = useContext(AppContext);
  const [homePageLoading, setHomePageLoading] = useState(false);
  const [megaLoading, setMegaLoading] = useState(false);
  const history = useHistory();

  const { search } = useLocation();
  const query = new URLSearchParams(search);

  useEffect(() => {
    const loadDesignData = async () => {
      if (query) {
        const isDemo = query.get("isDemo");
        const templateId = query.get(process.env.REACT_APP_TEMPLATEID_NAME);
        const token = query.get(process.env.REACT_APP_JWT_NAME);
        if (isDemo && templateId && token) {
          try {
            const appDesignData = await getDemoAppDesignData(token, templateId);
            setIsDemo(true);
            return setAppDesignData({ ...appDesignData });
          } catch (e) {
            console.log(e);
            history.replace("/");
          }
        }
      }
      const appDesignData = await getAppDesignData(store?.sap_website_id);
      setAppDesignData({ ...appDesignData });
    };
    store?.sap_website_id && loadDesignData();
  }, [store?.sap_website_id]);

  /**updating current language based on the list of data available */
  useEffect(() => {
    let localLanguage = "english";
    setDefaultLocale(
      locale?.find((item) => item?.language === localLanguage)?.strings
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMegaMenu = () => {
    setMegaLoading(true);
    getMainCategories(currentStore?.store_id)
      .then((res) => {
        updateCategory(res[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => setMegaLoading(false));
  };

  const getWishlistData = () => {
    getWishList(currentStore?.store_code)
      .then((wishList) => {
        let skuString = [];
        wishList?.map((item, index) => {
          skuString[index] = item?.product?.sku;
        });
        return skuString;
      })
      .then((resString) => {
        getProductFromSku(currentStore?.store_code, resString)
          .then((res) => {
            initializeFavourites(res);
          })
          .catch((e) => {
            console.log(e.message);
          });
      })
      .catch((e) => console.log(e));
  };

  const getCartData = () => {
    getCart(currentStore?.store_code)
      .then((res) => {
        let skuString = [];
        let discount = 0;
        res?.items?.map((item, index) => {
          skuString[index] = item?.sku;
          discount += item?.extension_attributes?.discount_amount;
        });
        if (discount > 0) {
          setCouponApplied(true);
        }
        getProductFromSku(currentStore?.store_code, skuString)
          .then((resTwo) => {
            res?.items?.map((i, index) => {
              resTwo.map((j) => {
                if (i.sku === j.sku) {
                  i.tax_included_price =
                    j.tax_included_special_price > 0
                      ? j.tax_included_special_price
                      : j.tax_included_price;
                  i.price =
                    j.tax_included_special_price > 0
                      ? j.special_price
                      : j.price;
                  i.salable_qty = j.salable_qty;
                  i.is_salable = j.is_salable;
                  i.image = j.image;
                  i.tax_percentage = j.tax_percentage;
                  i.is_salable = j.is_salable;
                  i.salable_qty = j.salable_qty;
                }
              });
            });
            initializeCart(res);
          })
          .catch((e) => console.log(e.message));
      })

      .catch(() =>
        createCart(currentStore?.store_code)
          .then((cartres) => {
            initializeCart({ id: cartres, items: [] });
          })
          .catch((e) => {
            if (
              e.message ===
              "The consumer isn't authorized to access %resources."
            ) {
              setShowToast(true);
              setToastData({
                type: "info",
                text: "Session Expired. Please login Again",
              });
              logout();
            }
          })
      );
  };

  // const getHomePageData = () => {
  //   setHomePageLoading(true);
  //   getDynamicHomePage(currentStore?.store_id)
  //     .then(res => setHomePageOrder(res?.data?.data))
  //     .catch(e => console.log(e))
  //     .finally(() => setHomePageLoading(false));
  // };

  const getInaamData = () => {
    getInaamPoints(
      profile?.custom_attributes
        ?.filter((item) => {
          return item?.attribute_code === "mobile_number";
        })[0]
        ?.value?.slice(0, 3) === "+91"
        ? profile?.custom_attributes
            ?.filter((item) => {
              return item?.attribute_code === "mobile_number";
            })[0]
            ?.value.slice(3)
        : profile?.custom_attributes
            ?.filter((item) => {
              return item?.attribute_code === "mobile_number";
            })[0]
            ?.value.slice(4)
    ).then((res) => setInaamData(res));
  };

  useEffect(() => {
    profile?.custom_attributes?.filter((item) => {
      return item?.attribute_code === "mobile_number";
    })[0]?.value && getInaamData();
  }, [isLoggedIn]);

  useEffect(() => {
    isLoggedIn && currentStore?.store_code && getCartData();
  }, [isLoggedIn, currentStore?.store_code, cartUpdated]);

  useEffect(() => {
    isLoggedIn && currentStore?.store_id && getWishlistData();
  }, [isLoggedIn, currentStore?.store_id, wishListUpdated]);

  useEffect(() => {
    if (currentStore?.store_id) {
      // getHomePageData();
      getMegaMenu();
    }
  }, [currentStore?.store_id]);

  const {
    ECOMWEB_MAINTENANCE_MODE,
    ECOMWEB_MAINTENANCE_MODE_TITLE,
    ECOMWEB_MAINTENANCE_MODE_MESSAGE,
  } = useContext(FlagsContext);
  return (
    <>
      {ECOMWEB_MAINTENANCE_MODE === "true" &&
      process.env.NODE_ENV === "production" ? (
        <SiteUnderMaintenance
          title={ECOMWEB_MAINTENANCE_MODE_TITLE}
          message={ECOMWEB_MAINTENANCE_MODE_MESSAGE}
        />
      ) : (
        <Nesto megaLoading={megaLoading} homePageLoading={homePageLoading} />
      )}
    </>
  );
};

const Nesto = ({ megaLoading, homePageLoading }) => {
  const location = useLocation();
  const { latLong } = useContext(AppContext);

  if (location?.pathname === "/download-app") {
    return <DownloadApp />;
  }

  if (location?.pathname === "/privacy") {
    return <PrivacyPolicy />;
  }

  if (location.pathname === "/inaam-app") {
    return <InaamDownload />;
  }

  if (location.pathname === "/nesto-app") {
    return <NestoApp />;
  }

  return (
    <>
      {!latLong?.zone || location.pathname === "/select-location" ? (
        <Location />
      ) : (
        <>
          <MainWeb
            megaLoading={megaLoading}
            homePageLoading={homePageLoading}
          />
        </>
      )}
      <WebUtils />
    </>
  );
};

const MainWeb = ({ megaLoading, homePageLoading }) => {
  const location = useLocation();
  const { breadcrumbs } = useContext(AppContext);

  return (
    <>
      {!location.search && (megaLoading || homePageLoading) && (
        <MainLoader
          outerClassName="main-loader-container"
          innerClassName="main-loader-animation"
        />
      )}

      <Header />
      {location.pathname !== "/" && (
        <Breadcrumbs breadcrumb={breadcrumbs} className="container" />
      )}
      <Routes />
      <Footer />
    </>
  );
};

const WebUtils = () => {
  const {
    toastData,
    showLogin,
    setShowLogin,
    cookiesAccepted,
    updateCookiesAccepted,
    isLoggedIn,
    addressBook,
    latLong,
    updateLocation,
    updateCurrentStore,
    updateActiveAddress,
    setAddressBook,
    setShowToast,
    setToastData,
    activeAddress,
    setStorePrices,
    profile,
  } = useContext(AppContext);
  const location = useLocation();
  const [isScroll, setIsScroll] = useState(false);
  const [modalDetails, setModalDetails] = useState({
    startsWith: "",
    endsWith: "",
    show: "",
  });
  const [addressLoaded, setAddressLoaded] = useState(false);
  const history = useHistory();

  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
      window.requestAnimationFrame(scrollToTop);
      window.scrollTo(0, c - c / 8);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 200) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    });
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getAllAddress()
        .then((res) => {
          setAddressBook(res[0].addresses);
          setAddressLoaded(true);
        })
        .catch((err) => {
          setShowToast(true);
          setToastData({
            type: "info",
            text: "Error fetching addresses",
          });
        });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const addressSelect = async () => {
      if (isLoggedIn && addressLoaded) {
        let defaultAddr = addressBook.filter(
          (addr) => addr.default_shipping === true
        );
        if (addressBook.length > 0 && defaultAddr.length === 0) {
          setModalDetails({
            startsWith: "new-message",
            endsWith: "save",
            show: true,
          });
        } else {
          const addr = defaultAddr[0];
          if (addressBook.length === 0) {
            if (latLong?.zone?.id) {
              getZoneById(latLong?.zone?.id)
                .then((res) => {
                  setStorePrices(res?.data?.zone_order);
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            updateActiveAddress(null);
            setAddressLoaded(false);
            return;
          }
          if ((activeAddress && !activeAddress?.zone_id) || !addr?.zone_id) {
            const areaResult = await getDetailsByCoords(
              addr?.latitude,
              addr?.longitude
            );
            const { flatAddr, newAddr } = makeAddressData(
              addr,
              areaResult.data,
              profile
            );
            await updateAddress({ address: newAddr });
            updateActiveAddress(flatAddr);
            window.location.reload();
          }
          if (!activeAddress) {
            const result = await getZoneById(addr?.zone_id);
            if (!result?.data?.id) {
              setAddressLoaded(false);
              return history.push("/select-location");
            }
            updateLocation(
              {
                area: {
                  label: addr?.area_name,
                  id: addr?.area_id,
                },
                city: {
                  label: addr?.city,
                  id: addr?.city_id,
                },
                zone: {
                  label: addr?.zone_name,
                  id: addr?.zone_id,
                },
                lat: addr?.latitude,
                long: addr?.longitude,
              },
              result?.data?.site
            );
            setStorePrices(result?.data?.zone_order);
            updateCurrentStore(result?.data?.site?.stores[0]);
            updateActiveAddress(addr);
          }
          if (activeAddress) {
            const result = await getZoneById(activeAddress?.zone_id);
            setStorePrices(result?.data?.zone_order);
          }
        }
        setAddressLoaded(false);
      }
    };
    addressSelect();
  }, [isLoggedIn, addressBook, addressLoaded]);

  useEffect(() => {
    if (!isLoggedIn && !latLong.zone) {
      history.push("/select-location");
    }
  }, []);

  return (
    <>
      <ToastMessage type={toastData?.type} text={toastData?.text} />
      <LoginPopup show={showLogin} onHide={setShowLogin} />
      <AddressDetails
        onClose={() => setModalDetails({ ...modalDetails, show: false })}
        show={modalDetails.show}
        startsWith={modalDetails.startsWith}
        endsWith={modalDetails.endsWith}
      />
      <Cookies
        cookiesAccepted={cookiesAccepted}
        updateCookiesAccepted={updateCookiesAccepted}
      />
      {isScroll ? (
        <div
          className={
            "scroll-to-top d-flex justify-content-center align-items-center" +
            (location.pathname.match("/products") ? " pdp-scroll-to-top" : "")
          }
          onClick={scrollToTop}
        >
          <BiArrowToTop color="#eee" size={22} />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default App;
