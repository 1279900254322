import React, { useContext, useEffect, useState } from "react";
import "./OrderStatusConfirm.css";
import OrderItem from "../../components/OrderItem/OrderItem";
import { useHistory } from "react-router";
import { cancelOrder, getOrder, returnOrder } from "../../api";
import { AppContext } from "../../context/AppContext";
import { OrderStatusConfirmLoader } from "../../components/SkeletonLoader";
import Loader from "react-loader-spinner";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import OrderItemView from "../../components/OrderItemView";

const OrderStatusConfirm = (props) => {
  const { setShowToast, setToastData, currentStore, setBreadcrumbs } =
    useContext(AppContext);
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const history = useHistory();
  const [text, setText] = useState("");
  const [reason, setReason] = useState("");
  const [statusType, setStatusType] = useState("");
  const [orderData, setOrderData] = useState({});
  const [orderReturnData, setOrderReturnData] = useState([]);
  const [returnPrice, setReturnPrice] = useState(0);
  const [orderLoading, setOrderLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const getOrderDetails = () => {
    setOrderLoading(true);
    let dummyData = [];
    let returnTempPrice = 0;
    getOrder(props.match.params.orderId)
      .then((res) => {
        setOrderData(res);
        props?.location?.state?.checkedItems?.map((item, index) => {
          dummyData[index] = res?.items?.filter(
            (fItem) => fItem.item_id === item.id
          )[0];
          returnTempPrice =
            returnTempPrice +
            parseFloat(
              dummyData[index]?.base_price_incl_tax *
                parseInt(dummyData[index]?.qty_ordered)
            );
        });
        setOrderReturnData(dummyData);
        setReturnPrice(returnTempPrice);
      })
      .finally(() => setOrderLoading(false));
  };
  useEffect(() => {
    setStatusType(props?.match?.params?.statusType);
    getOrderDetails();
  }, []);
  useEffect(() => {
    switch (props?.match?.params?.statusType) {
      case "return":
        if (!props?.location?.state?.checkedItems) {
          history.push(`/orderdetails/${props.match.params.orderId}`);
        }
        if (orderData) {
          setText({
            lhsHeading:
              "Return " +
              props?.location?.state?.checkedItems?.length +
              " Items",
            rhsHeading: "Confirm Return",
            reason: "Reason for Return",
            buttonText: "Confirm Return",
          });
        } else {
          history.push("/account/myorder");
        }
        break;

      case "replace":
        if (orderData) {
          setText({
            lhsHeading:
              "Replace " +
              props?.location?.state?.checkedItems?.length +
              " Items",
            rhsHeading: "Confirm Replacement",
            reason: "Reason for Replacement",
            buttonText: "Confirm Replacement",
          });
        } else {
          history.push("/account/myorder");
        }
        break;
      case "cancelorder":
        setText({
          lhsHeading: "Order Cancelation",
          rhsHeading: "Confirm Cancelation",
          reason: "Reason for Cancelation",
          buttonText: "CANCEL THIS ORDER",
        });
        break;

      default:
        setText({
          lhsHeading: "Default",
          rhsHeading: "Default",
          reason: "Default",
          buttonText: "Default",
        });
    }
  }, []);
  const handleButtonClick = (type) => {
    if (reason === "") {
      setShowToast(true);
      setToastData({
        type: "error",
        text: "Please Enter the Reason",
      });
      return 0;
    }
    setLoading(true);
    if (type === "cancelorder") {
      cancelOrder({
        sales_order_id: orderData?.entity_id,
        order_kind: "cancel",
        cancellation_type: "full",
        store_id: currentStore.store_id,
        cancel_reason: reason,
        increment_id: orderData?.increment_id,
        customer_id: orderData?.customer_id,
      })
        .then((res) => {
          if (res?.message !== "") {
            history.push("/order-status/order-cancel");
          }
        })
        .catch((e) => {
          if (!e?.success) {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e?.message,
            });
          }
        })
        .finally(() => setLoading(false));
    } else if (type === "return") {
      const payloadItems = props?.location?.state?.checkedItems.map(
        (item, index) => {
          return {
            id: item?.id,
            qty: item?.quantity,
            name: item?.name,
          };
        }
      );
      returnOrder({
        sales_order_id: orderData?.entity_id,
        items: payloadItems,
        return_reason: reason,
        order_kind: "return",
        cancellation_type: "partial",
      })
        .then((res) => {
          history.push("/order-status/order-return");
        })
        .catch((e) => {
          if (e?.item_expiry || !e?.success) {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message,
            });
          }
        })
        .finally(() => setLoading(false));
    }
  };

  useDocumentTitle(text.rhsHeading + " - Nesto Online Shopping");
  useEffect(() => {
    setBreadcrumbs([
      { title: "Home", path: "/" },
      { title: "Orders", path: "/account/orders" },
      {
        title: "Order details",
        path: `/orderdetails/${props?.match?.params?.orderId}`,
      },
      { title: text.rhsHeading },
    ]);
  }, [text.rhsHeading]);
  return (
    <div className="container my-2 py-2">
      {/* <div className="col-md-12">breadcrumb</div> */}
      {orderLoading ? (
        <OrderStatusConfirmLoader />
      ) : (
        <>
          <div className="row">
            <div className="my-2 col-lg-8 col-md-12">
              <div className="ror-container p-4">
                <h5 className="fw-700">{text.lhsHeading}</h5>
                {statusType === "cancelorder" ? (
                  <div className="my-4">
                    <div className="col">
                      <OrderItem
                        itemData={orderData}
                        status={orderData?.status}
                      />
                    </div>
                    <div
                      className="mx-5 px-5 py-3 my-4 text-center fs-14"
                      style={{
                        borderRadius: "15px",
                        backgroundColor: "#ffded1",
                      }}
                    >
                      Cancelling this order may cause you to lose promotional
                      offers and/or cashbacks on undispatched items in this
                      order .
                    </div>
                  </div>
                ) : (
                  <div className="px-md-5 mx-md-5">
                    {orderReturnData?.map((returnItem, index) => {
                      return (
                        <div key={index} className="my-1">
                          <OrderItemView item={returnItem} />
                        </div>
                      );
                    })}
                  </div>
                )}
                <div>
                  <label>{text.reason}</label>
                  <textarea
                    className="form-control"
                    placeholder={text.reason}
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    rows={3}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="my-2 col-lg-4 col-md-12">
              <div className="ror-container">
                <div className="rhs-section p-3 fs-16 fw-700">
                  {text.rhsHeading}
                </div>

                {statusType === "return" && (
                  <div className="rhs-section p-3">
                    <div className="d-flex justify-content-between">
                      <span>Items</span>
                      <span>
                        {props?.location?.state?.checkedItems?.length}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Previous Order Total</span>
                      <span>
                        {`${orderData?.base_currency_code} ${parseFloat(
                          orderData?.base_grand_total
                        ).toFixed(2)}`}
                      </span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Item Returned</span>
                      <span>
                        {`${orderData?.base_currency_code} -${parseFloat(
                          returnPrice
                        ).toFixed(2)}`}
                      </span>
                    </div>
                  </div>
                )}

                <div className="rhs-section p-3">
                  {orderData?.payment?.additional_information?.method_title ===
                    "Card Payment" && (
                    <>
                      <div className="rhs-section p-3">
                        <div>
                          <strong>
                            {`The balance amount of ${
                              orderData?.base_currency_code
                            } ${
                              statusType === "cancelorder"
                                ? parseFloat(
                                    orderData?.base_grand_total
                                  ).toFixed(2)
                                : returnPrice.toFixed(2)
                            } will be refunded to you via payment gateway.`}
                          </strong>
                          Please wait 7-14 business days to receive the refund.
                        </div>
                      </div>
                    </>
                  )}
                  <div className="d-flex justify-content-between fs-18 fw-700">
                    <span>Grand Total</span>
                    <span>
                      {statusType === "cancelorder" &&
                        orderData?.base_currency_code +
                          " " +
                          parseFloat(orderData?.base_grand_total).toFixed(2)}
                      {statusType === "return" &&
                        orderData?.base_currency_code +
                          " " +
                          parseFloat(
                            orderData?.base_grand_total - returnPrice
                          ).toFixed(2)}
                    </span>
                  </div>
                  <div style={{ fontSize: "10px", fontWeight: "bolder" }}>
                    VAT Included
                  </div>
                  <button
                    className="form-control profile-button profile-button-active mt-4"
                    onClick={() => handleButtonClick(statusType)}
                  >
                    {loading ? (
                      <Loader
                        type="TailSpin"
                        color={"white"}
                        height={20}
                        width={20}
                      />
                    ) : (
                      <>{text.buttonText}</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderStatusConfirm;
