/**
 * language data.
 *
 * @constant
 */

const locale = [
  {
    language: "english",
    strings: {
      FIRST_NAME: "First name",
      LAST_NAME: "Last name",
      EMAIL: "Email",
      ZIP_CODE: "Zip code",
      LOCATION: "Location",
      PHONE_NUMBER: "Phone number",
      COUNTRY: "Country",
      SELECT_A_COUNTRY: "Select a country",
      STATE: "State",
      SELECT_A_STATE: "Select a state",
      APT_NUMBER: "Apt/Villa number",
      BUILDING_NUMBER: "Building name",
      BUILDING_BLOCKS: "Building block",
      STREET_NAME: "Street name",
      DATE_OF_BIRTH: "Date of birth",
      GENDER: "Gender",
      MALE: "Male",
      FEMALE: "Female",
      CANCEL: "Cancel",
      DELIVER_THIS_HERE: "Deliver This Order Here",
      SAVE: "Save",
      NEXT_DELIVERIES: "Next Deliveries",
      DELIVERING_NOW_IN: "Delivering Now In",
      NEXT_AVAILABLE_TIMESLOT: "Next Available Timeslot",
      EXPRESS_TIMESLOT: "Express Timeslot",
      TIMESLOTS_OPEN: "Timeslots Open",
      GET_TO_KNOW_US: "Get To Know Us",
      ABOUT_US: "About Us",
      MORE_FROM_US: "More From Us",
      ORDERS_AND_RETURNS: "Orders & Returns",
      POLICIES: "Policies",
      PRIVACY_POLICY: "Privacy Policy",
      TERMS_AND_CONDITIONS: "Terms & Conditions",
      IN_THE_SPOTLIGHT: "In The Spotlight",
      PANTRY_SUPPLIES: "Pantry Supplies",
      BEVERAGES: "Beverages",
      BREAKFAST_AND_CEREAL: "Breakfast & Cereal",
      CONDIMENTS: "Condiments",
      CANNED_GOODS: "Canned Goods",
      STAY_CONNECTED: "Stay Connected",
      LANDING_PAGE_HEADING_ONE: "Need",
      LANDING_PAGE_HEADING_TWO: "Fruits?",
      LANDING_PAGE_DESCRIPTION_ONE:
        "Start shopping your groceries and essentials with fast Express delivery,",
      LANDING_PAGE_DESCRIPTION_TWO: "select your location to get started",
      SEARCH_YOUR_LOCATION: "Search Your Location",
      LANDING_PAGE_AD_ONE_TITLE: "Wide range of categories",
      LANDING_PAGE_AD_TWO_TITLE: "Amazing Deals and offers",
      LANDING_PAGE_AD_THREE_TITLE: "Delivery on time",
      LANDING_PAGE_AD_ONE_DESCRIPTION:
        "Browse from wide range of categories for Grocery and households.",
      LANDING_PAGE_AD_TWO_DESCRIPTION:
        "Enjoy amazing deals and special offers from your favourite Nesto hypermarket.",
      LANDING_PAGE_AD_THREE_DESCRIPTION:
        "Our highly effecient delivery team will ensure that your delivery is fast and on time.",
      LANDING_PAGE_APP_BANNER_HEADING_ONE: "Get the",
      LANDING_PAGE_APP_BANNER_HEADING_TWO: "Nesto App",
      LANDING_PAGE_APP_BANNER_DESCRIPTION:
        "Make your delivery easy by getting all kinds of grocery and consumables in a couple of taps.",
      DOWNLOAD_THE_APP_ONE: "Download The App",
      DOWNLOAD_THE_APP_TWO: "From",
      SEARCH_BAR_PLACEHOLDER: "What are you looking for...",
      HOME: "Home",
      CATEGORIES: "Categories",
      NO_PRODUCTS_FOUND: "No Products Found",
      NO_PRODUCTS_FOUND_DESCRIPTION:
        "This category does not have any Products.",
      NO_FAVOURITES: "No Favourites",
      NO_FAVOURITES_DESCRIPTION: "You haven't added any item to the list.",
      WISHLIST: "Wishlist",
      SORT_AND_FILTER: "Sort & Filter",
      PROFILE: "Profile",
      PERSONAL_INFORMATION: "Personal Information",
      EDIT: "Edit",
      INAAM_POINTS: "Inaam Points",
      INAAM_POINTS_EARNED: "Inaam points earned",
      ORDER_INFORMATION: "Order Information",
      MY_ORDERS: "My Orders",
      ADDRESS_BOOK: "Address Book",
      NOTIFICATION: "Notification",
      MY_WISHLIST: "My Wishlist",
      REFER_A_FRIEND: "Refer A Friend",
      HELP: "Help",
      ABOUT_NESTO: "About Nesto",
      WRITE_FEEDBACK: "Write Feedback",
      LOGOUT: "Logout",
      LOGIN: "Login",
      LOGIN_TO_CONTINUE: "Login to continue",
      CURRENT_POINTS: "Current Points",
      INAAM_ID: "Inaam ID",
      ON_GOING: "On-Going",
      COMPLETED: "Completed",
      ACTIVE_ORDERS: "Active Orders",
      COMPLETED_ORDERS: "Completed Orders",
      FAILED_AND_CANCELLED_ORDERS: "Failed & Cancelled Orders",
      MY_ADDRESS: "My Address",
      ADD_NEW_ADDRESS: "Add new address",
      DELETE: "Delete",
      CLEAR_ALL: "Clear All",
      FAVOURITES: "Favourites",
      REFER_A_FRIEND_HEADING: "Share To Your Friend",
      REFER_A_FRIEND_DESCRIPTION:
        "Invite Your Friends To Nesto And Shop Together",
      YOUR_CODE: "Your Code",
      SHARE_YOUR_LINK: "Share your link",
      REFERRAL_MESSAGE_ONE:
        "invited you to join Nesto Online Shopping. Use the referral code",
      REFERRAL_MESSAGE_TWO: "to sign up.",
      ABOUT_NESTO_HEADING_ONE: "Who We Are",
      ABOUT_NESTO_DESCRIPTION_ONE_ONE:
        "Nesto Hypermarket is a well-known name across homes in the Gulf region. Founded by Mr. K P Basheer, Chairman of Western International Group in 2004 and led by directors Mr. Siddique Pallolathil & Mr.Jamal Kp, Nesto has been one of the fastest growing retail chains in the Gulf Cooperation Council (GCC) locations.",
      ABOUT_NESTO_DESCRIPTION_ONE_TWO:
        "The Group’s tagline – ‘Nesto, all that you need’ – represents the company’s vision of being a one-stop shop for the people in the region. It caters to the multi-ethnic community residing in all its locations and therefore, stocks a huge amount of international products besides the locally manufactured items.",
      ABOUT_NESTO_HEADING_TWO: "Our Mission",
      ABOUT_NESTO_DESCRIPTION_TWO:
        "To provide a great shopping experience and integral part of our loyal customers lives, by offering high quality shopping experiences through good product, quality at even better prices.",
      NAME: "Name",
      FULL_NAME: "Full Name",
      FEEDBACK_TYPE: "Feedback Type",
      FEEDBACK: "Feedback",
      FEEDBACK_OPTION_ZERO: "Select",
      FEEDBACK_OPTION_ONE: "Website experience",
      FEEDBACK_OPTION_TWO: "Technical",
      FEEDBACK_OPTION_THREE: "Operational",
      SEND_AN_EMAIL: "Send an email",
      YOUR_CART: "Your Cart",
      NO_ITEMS_IN_CART: "No items in cart",
      EMPTY_CART_HEADING: "Cart Empty",
      EMPTY_CART_DESCRIPTION: "Add items to your cart to view them here",
      ADD_TO_CART: "Add to cart",
      REMOVE_FROM_CART: "Remove from cart",
      QUANTITY_IN_CART: "Quantity In Cart",
      DETAILS: "Details",
      PRODUCTDETAILS_AD_BANNER_ONE: "Free Delivery for orders with AED",
      PRODUCTDETAILS_AD_BANNER_TWO: "Products delivered to your doorstep",
      PRODUCTDETAILS_AD_BANNER_THREE: "Contact us at anytime",
      LOADING_MESSAGE: "Hey, We are reaching out for your items",
      BESTSELLERS: "Bestsellers",
      POPULAR_ITEMS: "Popular Items",
      GO_TO_CART: "Go to cart",
      GO_BACK: "Go back",
      GO_TO_CHECKOUT: "Go to checkout",
      CART_TOTAL: "Cart Total",
      ORDER_SUMMARY: "Order summary",
      ITEM: "Item",
      ITEMS: "Items",
      PRICE: "Price",
      QTY: "Quantity",
      TOTAL: "Total",
      VAT_INCLUSIVE: "VAT Inclusive",
      AMOUNT_EXCL_VAT: "Amount (Excl VAT)",
      CONTINUE_TO_SHOP: "Continue shopping",
      DISCOUNT: "Discount",
      OUT_OF_STOCK: "Out of stock",
      DELIVERY_TIME: "Delivery Time",
      DELIVERY_ADDRESS: "Delivery Address",
      TODAY: "Today",
      TOMORROW: "Tomorrow",
      NO_TIMESLOTS_AVAILABLE: "No timeslots available",
      NO_ADDRESSES_AVAILABLE:
        "No Deliverable Address Found for the Current Store",
      SELECT_ADDRESS_PLACEHOLDER: "Please select an address",
      PROCEED: "Proceed",
      ALLOW_SUBSTITUTION: "Allow Substitution",
      ITEMS_IN_CART: "Items in cart",
      SHIPPING_EXCL_VAT: "Shipping Fee (Excl VAT)",
      GRAND_TOTAL: "Grand Total",
      APPLICATION: "Application",
      COUPON_LABEL: "Apply Coupon",
      COUPON_PLACEHOLDER: "Enter coupon code",
      PLACE_ORDER: "Place Order",
      PAYMENT_METHOD: "Payment Method",
      SELECT_PAYMENT_METHOD: "Select Payment Method",
      DELIVERY_NOTES: "Delivery Notes",
      SUBSTITUTION_POPOVER_HEADING: "Substitution",
      SUBSTITUTION_POPOVER_DESCRIPTION:
        "When enabled, if you miss the substitution suggestion time limit of 10 minutes, Nesto will automatically allocate the next best substitute for any out-of-stock items.",
      ORDER_PLACED: "Order Placed",
      ORDER_PLACED_MESSAGE:
        "Your order has been confirmed. You can check the status of this order from my orders page.",
      ORDER_STATUS_WAITING: "Getting your order Status",
      ORDER_STATUS_WAITING_MESSAGE:
        "Your order status is being retrieved. Please wait.",
      ORDER_RETURNED: "Order Returned",
      ORDER_RETURNED_MESSAGE:
        "Your order return has been initiated. You can check the status of this order from my orders page.",
      ORDER_CANCELLED: "Order Cancelled",
      ORDER_CANCELLED_MESSAGE:
        "Your order has been cancelled. You can check the status of this order from my orders page.",
      ORDER_FAILED: "Order Failed",
      ORDER_FAILED_MESSAGE:
        "Sorry, we couldnt place the order for you. Please try again.",
      ORDER_STATUS: "Order Status",
      RETRY_PAYMENT: "Retry Payment",
      SHOW_ORDER_DETAILS: "Show Order Details",
      ORDER_DETAILS: "Order Details",
      SHOP_MORE: "Shop More",
      ADD_A_NOTE: "Add a note",
      EDIT_NOTE: "Edit note",
      DONE: "Done",
      SIMILAR_PRODUCTS: "Similar products",
      UPDATE: "Update",
      REMOVE: "Remove",
      YES: "Yes",
      NO: "No",
      CONFIRM_STORE_CHANGE: "Confirm Store Change",
      CONFIRM_STORE_CHANGE_DESCRIPTION:
        "The location you have chosen is serviced by a different store, Hence your cart and wishlist will be replaced with that of the selected store.",
      SIGN_IN: "Sign In",
      CREATE_NEW_ACCOUNT: "Create New Account",
      CHANGE: "Change",
      MAP_LOCATION_CHANGE_MESSAGE:
        "Move the map pin to find your loaction and select the delivery address. Please place the pin accurately.",
      CONFIRM_LOCATION: "Confirm Location",
      ALREADY_A_CUSTOMER_QUESTIONMARK: "Already A Customer?",
      ORDER_DETAILS_NOT_FOUND: "Order details not found.",
      REPORT_MISSING_ITEM: "Report missing items",
      PRODUCTS: "Products",
      DOWNLOAD_TAX_INVOICE: "Download Tax Invoice",
      BACK_TO_ORDER_DETAILS: "Back to Order Details",
      CART: "Cart",
      ORDERS: "Orders",
      CHECKOUT: "Checkout",
      SOMETHING_WENT_WRONG:
        "Something went wrong! please refresh and try again.",
      RELOAD: "Reload",
      PAGE_NOT_FOUND_MSG:
        "The page you are trying to access does not exist or is not accessible.",
      PAGE_NOT_FOUND_HEAD: "404 - Page Not Found",
      SEEN_IT_ALL_MSG: "Yay! You have seen it all",
      SEARCH_AGAIN_HEAD: "Search Again!",
      SEARCH: "Search",
      SEARCH_AGAIN_MSG: "No results available for this search.",
      EDIT_ORDER: "Edit Order",
      CONFIRM_RETURN: "Confirm Return",
      REASON_FOR_RETURN: "Reason for Return",
      CONFIRM_REPLACEMENT: "Confirm Replacement",
      REASON_FOR_REPLACEMENT: "Reason for Replacement",
      RETURN: "Return",
      REPLACE: "Replace",
      ORDER_CANCELATION: "Order Cancelation",
      CONFIRM_CANCELATION: "Confirm Cancelation",
      REASON_FOR_CANCELATION: "Reason for Cancelation",
      CANCEL_THIS_ORDER: "Cancel this order",
      PLEASE_ENTER_A_REASON: "Please enter a reason",
      ORDER_CANCEL_WARNING:
        "Cancelling this order may cause you to lose promotional offers and/or cashbacks on undispatched items in this order.",
      PREVIOUS_ORDER_TOTAL: "Previous Order Total",
      ITEMS_RETURNED: "Items Returned",
      REFUND_WARNING: "Please wait 7-14 business days to receive the refund.",
      CART_UPDATE_WARNING_MESSAGE:
        "Updating the cart will remove the coupon code. Do you still want to continue?",
      ARE_YOU_SURE: "Are you sure?",
      REMOVE_FAV_WARNING: "Do you want to remove this item?",
      NO_DETAILS_AVAILABLE: "No details available",
      NO_PRODUCT_FOUND: "No product found",
      UPDATE_CART: "Update cart",
      NO_DESCRIPTION_AVAILABLE: "No description available",
      FREQUENTLY_BROUGHT_TOGETHER: "Frequently Brought Together",
      EDIT_ADDRESS: "Edit address",
      NO_ADDRESS_FOUND: "No address found",
      SHOP_MORE_FOR_NOTIFICATION:
        "Shop more to see some awesome notifications :)",
      NO_NOTIFICATIONS: "All caught up!",
      NO_ACTIVE_ORDERS_HEADING: "No Active Orders",
      NO_COMPLETED_ORDERS_HEADING: "No Completed Orders",
      NO_ORDERS_DESCRIPTION: "Create an order to view them here",
      SELECT_LANGUAGE: "Select language",
      INAAM_REDEEM_MSG:
        "A coupon corresponding to the inaam points redeemed will be applied to the order automatically. The maximum inaam point that can be redeemed for this order will be",
      REDEEM_INAAM: "Redeem Inaam",
      REDEEM_POINTS: "Redeem a discount of",
      INAAM_POINTS_AVAILABLE: "Inaam Points Available",
      REDEEM: "Redeem",
      ALL_CATEGORIES: "All Categories",
      ACCOUNT: "Account",
      VIEW_ORDER_STATUS: "View order status",
      VAT: "VAT",
      MY_CART: "My Cart",
      ORDER_IS_PENDING: "Order is pending",
      ORDER_IS_BEING_PICKED: "Order is being picked",
      ORDER_PICKED: "Order picked",
      ORDER_IS_BEING_PACKED: "Order is being packed",
      ORDER_PACKED: "Order packed",
      PAYMENT_REVIEW: "Payment review",
      PAYMENT_PENDING: "Payment pending",
      ORDER_IS_BEING_PROCESSED: "Order is being processed",
      ORDER_IS_ON_HOLD: "Order is on hold",
      PAYMENT_FAILED: "Payment failed",
      ORDER_CLOSED: "Order closed",
      SUSPECTED_FRAUD: "suspected fraud",
      READY_FOR_DELIVERY: "Ready for delivery",
      ON_THE_WAY: "On the way",
      DELIVERED: "Delivered",
      RETURN_INITIATED: "Return initiated",
      RETURN_COLLECTED: "Return collected",
      ORDER_PARTIALLY_RETURNED: "Order partially returned",
      ORDER_STATUS_UNKNOWN: "Order status unknown",
      MADE_A_MISTAKE: "Made a mistake?",
      EDIT_THIS_ORDER: "Edit this order",
      PAYMENT_INCOMPLETE: "Payment incomplete",
      HAVING_PROBLEM: "Having problem?",
      RETURN_ITEMS: "Return items",
      DELIVERED_ON: "Delivered on",
      DELIVERY_TIME_NOT_AVAILABLE: "Delivery time not available",
      DELIVERY_ON: "Delivery on",
      POINTS: "Points",
      POINTS_NOT_AVAILABLE: "Points not available",
      ARE_YOU_SURE_TO_LOGOUT: "Are you sure to log out?",
      ARE_YOU_SURE_ABOUT_DELETING_ADDRESS:
        "Are you sure about deleting this Address?",
      REMOVE_THIS_PRODUCT: "Remove this product?",
      REMOVE_THIS_PRODUCT_WARNING_MSG:
        "A minimum quantity required for purchasing this item:",
      DO_YOU_WANT_TO_REMOVE_THIS_COUPON: "Do you want to remove this coupon?",
      COUPON_REMOVAL_ALERT_MSG:
        "By removing this coupon you may lose promotional offers and/or cashbacks applied to this order.",
      DO_YOU_WANT_TO_EDIT_THIS_ORDER: "Do you want to edit this order?",
      HAVE_A_REFFERAL_CODE: "Have a referral code?",
      REFERRAL_CODE: "Referral code",
      YOUR_HAVE_SIGNED_IN: "You have signed in.",
      SIGN_IN_SUCCESS_MSG: "You have created an account successfully.",
      WELCOME_TO: "Welcome to",
      NESTO_ONLINE_SHOPPING: "Nesto Online Shopping",
      WELCOME_BACK: "Welcome back",
      WELCOME_BACK_MSG:
        "To enjoy shopping to its full extend, let's sign you in.",
      GET_OTP: "Get OTP",
      GETTING_STARTED: "Getting started",
      CREATE_AN_ACCOUNT_TO_CONTINUE: "Create an account to continue.",
      OTP_AUTHENTICATION: "OTP Authentication",
      OTP_SENT_TO: "OTP sent to",
      DIDNT_RECEIVE_CODE: "Didn't receive code?",
      RESEND: "Resend",
      CONTINUE: "Continue",
      SELECT: "Select",
    },
    alignment: "LTR",
  },
  {
    language: "arabic",
    strings: {
      FIRST_NAME: "पहला नाम",
      LAST_NAME: "उपनाम",
      EMAIL: "ईमेल",
      ZIP_CODE: "ज़िप कोड",
      LOCATION: "स्थान",
      PHONE_NUMBER: "फ़ोन नंबर",
      COUNTRY: "देश",
      SELECT_A_COUNTRY: "कोई देश चुनें",
      STATE: "राज्य",
      SELECT_A_STATE: "कोई राज्य चुनें",
      APT_NUMBER: "विला नंबर",
      BUILDING_NUMBER: "इमारत का नाम",
      BUILDING_BLOCKS: "भवन मार्ग",
      STREET_NAME: "सड़क का नाम",
      DATE_OF_BIRTH: "जन्म की तारीख",
      GENDER: "लिंग",
      MALE: "पुरुष",
      FEMALE: "महिला",
      CANCEL: "रद्द करें",
      DELIVER_THIS_HERE: "यह आदेश यहां वितरित करें",
      SAVE: "सहेजें",
      NEXT_DELIVERIES: "अगली डिलीवरी",
      DELIVERING_NOW_IN: "अभी डिलीवर कर रहे हैं",
      NEXT_AVAILABLE_TIMESLOT: "अगला उपलब्ध टाइमलॉट",
      EXPRESS_TIMESLOT: "एक्सप्रेस टाइम्सलॉट",
      TIMESLOTS_OPEN: "टाइम्सलॉट ओपन",
      GET_TO_KNOW_US: "हम के बारे में जानें",
      ABOUT_US: "हमारे बारे में",
      MORE_FROM_US: "हमसे अधिक",
      ORDERS_AND_RETURNS: "खरीद और वापसी",
      POLICIES: "नीतियों",
      PRIVACY_POLICY: "गोपनीयता नीति",
      TERMS_AND_CONDITIONS: "नियम एवं शर्तें",
      IN_THE_SPOTLIGHT: "सुर्खियों में",
      PANTRY_SUPPLIES: "पेन्ट्री आपूर्ति",
      BEVERAGES: "पेय",
      BREAKFAST_AND_CEREAL: "नाश्ते का अनाज",
      CONDIMENTS: "मसालों",
      CANNED_GOODS: "डिब्बाबंद वस्तुएँ",
      STAY_CONNECTED: "जुड़े रहें",
      LANDING_PAGE_HEADING_ONE: "फल",
      LANDING_PAGE_HEADING_TWO: "चाहिए?",
      LANDING_PAGE_DESCRIPTION_ONE:
        "फास्ट एक्सप्रेस डिलीवरी के साथ अपनी किराने का सामान और आवश्यक सामान खरीदना शुरू करें,",
      LANDING_PAGE_DESCRIPTION_TWO: "आरंभ करने के लिए अपना स्थान चुनें",
      SEARCH_YOUR_LOCATION: "अपना स्थान खोजें",
      LANDING_PAGE_AD_ONE_TITLE: "श्रेणियों की विस्तृत श्रृंखला",
      LANDING_PAGE_AD_TWO_TITLE: "अद्भुत सौदे और ऑफ़र",
      LANDING_PAGE_AD_THREE_TITLE: "समय पर डिलीवरी",
      LANDING_PAGE_AD_ONE_DESCRIPTION:
        "किराना और घरों के लिए श्रेणियों की विस्तृत श्रृंखला से ब्राउज़ करें।",
      LANDING_PAGE_AD_TWO_DESCRIPTION:
        "अपने पसंदीदा नेस्टो हाइपरमार्केट से अद्भुत सौदों और विशेष प्रस्तावों का आनंद लें।",
      LANDING_PAGE_AD_THREE_DESCRIPTION:
        "हमारी अत्यधिक कुशल डिलीवरी टीम यह सुनिश्चित करेगी कि आपकी डिलीवरी तेज और समय पर हो।",
      LANDING_PAGE_APP_BANNER_HEADING_ONE: "प्राप्त करें",
      LANDING_PAGE_APP_BANNER_HEADING_TWO: "नेस्टो ऐप",
      LANDING_PAGE_APP_BANNER_DESCRIPTION:
        "एक दो टैप में सभी प्रकार की किराना और उपभोग्य सामग्रियों को प्राप्त करके अपनी डिलीवरी को आसान बनाएं।",
      DOWNLOAD_THE_APP_ONE: "ऐप डाउनलोड करें",
      DOWNLOAD_THE_APP_TWO: "यहाँ से",
      SEARCH_BAR_PLACEHOLDER: "आप क्या ढूंढ रहे हैं...",
      HOME: "होम",
      CATEGORIES: "श्रेणियाँ",
      NO_PRODUCTS_FOUND: "कोई उत्पाद नहीं मिला",
      NO_PRODUCTS_FOUND_DESCRIPTION: "इस श्रेणी में कोई उत्पाद नहीं है।",
      NO_FAVOURITES: "कोई पसंदीदा नहीं",
      NO_FAVOURITES_DESCRIPTION: "आपने सूची में कोई आइटम नहीं जोड़ा है।",
      WISHLIST: "इच्छा-सूची",
      SORT_AND_FILTER: "क्रमबद्ध करें और फ़िल्टर करें",
      PROFILE: "प्रोफ़ाइल",
      PERSONAL_INFORMATION: "व्यक्तिगत जानकारी",
      EDIT: "संपादित करें",
      INAAM_POINTS: "इनाम अंक",
      INAAM_POINTS_EARNED: "अर्जित इनाम अंक",
      ORDER_INFORMATION: "खरीददारी की जानकारी",
      MY_ORDERS: "मेरी खरीददारी",
      ADDRESS_BOOK: "पता पुस्तिका",
      NOTIFICATION: "अधिसूचना",
      MY_WISHLIST: "मेरी इच्छा सूची",
      REFER_A_FRIEND: "आमंत्रण",
      HELP: "मदद",
      ABOUT_NESTO: "नेस्टो के बारे में",
      WRITE_FEEDBACK: "प्रतिक्रिया लिखें",
      LOGOUT: "लॉग आउट",
      LOGIN: "लॉग इन करें",
      LOGIN_TO_CONTINUE: "जारी रखने के लिए लॉगिन करें",
      CURRENT_POINTS: "वर्तमान अंक",
      INAAM_ID: "इनाम पहचान संख्या",
      ON_GOING: "चल रही है",
      COMPLETED: "पूरा हुआ",
      ACTIVE_ORDERS: "सक्रिय खरीदारी",
      COMPLETED_ORDERS: "पूरी की गई खरीदारी",
      FAILED_AND_CANCELLED_ORDERS: "विफल और रद्द की गई खरीदारी",
      MY_ADDRESS: "मेरा पता",
      ADD_NEW_ADDRESS: "नया पता जोड़ें",
      DELETE: "हटाएं",
      CLEAR_ALL: "सभी साफ करें",
      FAVOURITES: "पसंदीदा",
      REFER_A_FRIEND_HEADING: "अपने दोस्त को शेयर करें",
      REFER_A_FRIEND_DESCRIPTION:
        "अपने दोस्तों को नेस्टो में आमंत्रित करें और साथ में खरीदारी करें",
      YOUR_CODE: "आपका कोड",
      SHARE_YOUR_LINK: "Share your link",
      REFERRAL_MESSAGE_ONE:
        "ने नेस्टो ऑनलाइन शॉपिंग में शामिल होने के लिए आपको आमंत्रित किया। साइन अप करने के लिए रेफरल कोड",
      REFERRAL_MESSAGE_TWO: "का उपयोग करें।",
      ABOUT_NESTO_HEADING_ONE: "हम कौन हैं",
      ABOUT_NESTO_DESCRIPTION_ONE_ONE:
        "नेस्टो हाइपरमार्केट खाड़ी क्षेत्र के घरों में एक जाना-पहचाना नाम है। 2004 में वेस्टर्न इंटरनेशनल ग्रुप के चेयरमैन श्री के पी बशीर द्वारा स्थापित और निदेशकों श्री सिद्दीकी पल्लोलाथिल और श्री जमाल केपी के नेतृत्व में, नेस्टो गल्फ कोऑपरेशन काउंसिल (जीसीसी) स्थानों में सबसे तेजी से बढ़ती खुदरा श्रृंखलाओं में से एक रहा है।",
      ABOUT_NESTO_DESCRIPTION_ONE_TWO:
        "समूह की टैगलाइन - 'नेस्टो, ऑल दैट यू नीड' - क्षेत्र के लोगों के लिए वन-स्टॉप शॉप होने के कंपनी के दृष्टिकोण का प्रतिनिधित्व करती है। यह अपने सभी स्थानों में रहने वाले बहु-जातीय समुदाय को पूरा करता है और इसलिए, स्थानीय रूप से निर्मित वस्तुओं के अलावा बड़ी मात्रा में अंतरराष्ट्रीय उत्पादों का स्टॉक करता है।",
      ABOUT_NESTO_HEADING_TWO: "हमारा विशेष कार्य",
      ABOUT_NESTO_DESCRIPTION_TWO:
        "खरीदारी का शानदार अनुभव और हमारे वफादार ग्राहकों के जीवन का अभिन्न अंग प्रदान करने के लिए, अच्छे उत्पाद के माध्यम से उच्च गुणवत्ता वाले खरीदारी अनुभव, और भी बेहतर कीमतों पर गुणवत्ता प्रदान करना।",
      NAME: "नाम",
      FULL_NAME: "पूरा नाम",
      FEEDBACK_TYPE: "प्रतिक्रिया प्रकार",
      FEEDBACK: "प्रतिपुष्टि",
      FEEDBACK_OPTION_ZERO: "कृपया चयन कीजिए",
      FEEDBACK_OPTION_ONE: "वेबसाइट का अनुभव",
      FEEDBACK_OPTION_TWO: "तकनीकी",
      FEEDBACK_OPTION_THREE: "आपरेशनल",
      SEND_AN_EMAIL: "ईमेल भेजो",
      YOUR_CART: "आपकी कार्ट",
      NO_ITEMS_IN_CART: "कार्ट में कोई आइटम नहीं",
      EMPTY_CART_HEADING: " कार्ट खाली",
      EMPTY_CART_DESCRIPTION: "आइटम को यहां देखने के लिए अपनी कार्ट में जोड़ें",
      ADD_TO_CART: "कार्ट में जोड़ें",
      REMOVE_FROM_CART: "कार्ट से निकालें",
      QUANTITY_IN_CART: "कार्ट में मात्रा",
      DETAILS: "विवरण",
      PRODUCTDETAILS_AD_BANNER_ONE: "निःशुल्क डिलीवरी  के लिए ऑर्डर - AED",
      PRODUCTDETAILS_AD_BANNER_TWO: "आपके दरवाजे पर पहुंचाए गए उत्पाद",
      PRODUCTDETAILS_AD_BANNER_THREE: "हमसे किसी भी समय संपर्क करें",
      LOADING_MESSAGE: "अरे, हम आपके सामान के लिए संपर्क कर रहे हैं",
      BESTSELLERS: "सर्वाधिक बिकाऊ",
      POPULAR_ITEMS: "लोकप्रिय आइटम",
      GO_TO_CART: "कार्ट पर जाना",
      GO_BACK: "वापस जाओ",
      GO_TO_CHECKOUT: "चेक आउटिंग पर जाएं",
      CART_TOTAL: "कार्ट कुल",
      ORDER_SUMMARY: "खरीद सारांश",
      ITEM: "आइटम",
      ITEMS: "आइटम",
      PRICE: "कीमत",
      QTY: "मात्रा",
      TOTAL: "कुल",
      VAT_INCLUSIVE: "वैट समावेशी",
      AMOUNT_EXCL_VAT: "राशि (वैट को छोड़कर)",
      CONTINUE_TO_SHOP: "खरीदारी जारी रखें",
      DISCOUNT: "छूट",
      OUT_OF_STOCK: "स्टॉक ख़त्म",
      DELIVERY_TIME: "डिलीवरी का समय",
      DELIVERY_ADDRESS: "डिलिवरी का पता",
      TODAY: "आज",
      TOMORROW: "आने वाला कल",
      NO_TIMESLOTS_AVAILABLE: "कोई टाइमस्लॉट उपलब्ध नहीं है",
      NO_ADDRESSES_AVAILABLE:
        "वर्तमान स्टोर के लिए कोई वितरण योग्य पता नहीं मिला",
      SELECT_ADDRESS_PLACEHOLDER: "कृपया एक पता चुनें",
      PROCEED: "आगे बढ़ना",
      ALLOW_SUBSTITUTION: "प्रतिस्थापन की अनुमति दें",
      ITEMS_IN_CART: "कार्ट में आइटम",
      SHIPPING_EXCL_VAT: "शिपिंग शुल्क (वैट को छोड़कर)",
      GRAND_TOTAL: "कुल योग",
      APPLICATION: "ऐप्स",
      COUPON_LABEL: "कूपन लागू करें",
      COUPON_PLACEHOLDER: "कूपन कोड डालें",
      PLACE_ORDER: "ऑर्डर रखें",
      PAYMENT_METHOD: "भुगतान विधि",
      SELECT_PAYMENT_METHOD: "भुगतान का तरीका चुनें",
      DELIVERY_NOTES: "वितरण नोट",
      SUBSTITUTION_POPOVER_HEADING: "प्रतिस्थापन",
      SUBSTITUTION_POPOVER_DESCRIPTION:
        "सक्षम होने पर, यदि आप 10 मिनट की प्रतिस्थापन सुझाव समय सीमा चूक जाते हैं, तो नेस्टो स्वचालित रूप से किसी भी आउट-ऑफ-स्टॉक आइटम के लिए अगला सबसे अच्छा विकल्प आवंटित करेगा।",
      ORDER_PLACED: "ऑर्डर रखा",
      ORDER_PLACED_MESSAGE:
        "आपके आदेश की पुष्टि हो गई है। आप मेरे आदेश पृष्ठ से इस आदेश की स्थिति की जांच कर सकते हैं।",
      ORDER_STATUS_WAITING: "अपने आदेश की स्थिति प्राप्त करना",
      ORDER_STATUS_WAITING_MESSAGE: "अपने आदेश की स्थिति प्राप्त करना",
      ORDER_RETURNED: "आदेश वापस आ गया",
      ORDER_RETURNED_MESSAGE:
        "आपका ऑर्डर रिटर्न शुरू कर दिया गया है। आप मेरे आदेश पृष्ठ से इस आदेश की स्थिति की जांच कर सकते हैं।",
      ORDER_CANCELLED: "आदेश रद्द किया गया",
      ORDER_CANCELLED_MESSAGE:
        "आपका ऑर्डर खारिज कर दिया गया है। आप मेरे आदेश पृष्ठ से इस आदेश की स्थिति की जांच कर सकते हैं।",
      ORDER_FAILED: "आदेश विफल",
      ORDER_FAILED_MESSAGE:
        "क्षमा करें, हम आपके लिए आदेश नहीं दे सके। कृपया पुन: प्रयास करें।",
      ORDER_STATUS: "आदेश की स्थिति",
      RETRY_PAYMENT: "भुगतान पुनः आजमाएं",
      SHOW_ORDER_DETAILS: "आदेश विवरण दिखाएं",
      ORDER_DETAILS: "ऑर्डर का विवरण",
      SHOP_MORE: "अधिक खरीदारी करें",
      ADD_A_NOTE: "टिप्पणी जोड़ें",
      EDIT_NOTE: "नोट संपादित करें",
      DONE: "हो गया",
      SIMILAR_PRODUCTS: "इसी तरह के उत्पादों",
      UPDATE: "अद्यतन",
      REMOVE: "हटाना",
      YES: "हां",
      NO: "नहीं",
      CONFIRM_STORE_CHANGE: "स्टोर परिवर्तन की पुष्टि करें",
      CONFIRM_STORE_CHANGE_DESCRIPTION:
        "आपके द्वारा चुना गया स्थान एक अलग स्टोर द्वारा सेवित है, इसलिए आपकी कार्ट और इच्छा सूची को चयनित स्टोर से बदल दिया जाएगा.",
      SIGN_IN: "साइन इन करें",
      CREATE_NEW_ACCOUNT: "नया खाता बनाएँ",
      CHANGE: "परिवर्तन",
      MAP_LOCATION_CHANGE_MESSAGE:
        "अपना स्थान खोजने के लिए मानचित्र पिन को स्थानांतरित करें और वितरण पता चुनें। कृपया पिन सही तरीके से लगाएं.",
      CONFIRM_LOCATION: "स्थान की पुष्टि करें",
      ALREADY_A_CUSTOMER_QUESTIONMARK: "पहले से ही एक ग्राहक?",
      ORDER_DETAILS_NOT_FOUND: "आदेश विवरण नहीं मिला।",
      REPORT_MISSING_ITEM: "गुम वस्तुओं की रिपोर्ट करें",
      PRODUCTS: "उत्पादों",
      DOWNLOAD_TAX_INVOICE: "टैक्स चालान डाउनलोड करें",
      BACK_TO_ORDER_DETAILS: "खरीद विवरण के लिए वापस जाएं",
      CART: "कार्ट",
      ORDERS: "आदेश",
      CHECKOUT: "चेक आउट",
      SOMETHING_WENT_WRONG:
        "कुछ गलत हो गया! कृपया रीफ़्रेश करें और पुन: प्रयास करें.",
      RELOAD: "पुनः लोड करें",
      PAGE_NOT_FOUND_MSG:
        "आप जिस पृष्ठ तक पहुँचने का प्रयास कर रहे हैं वह मौजूद नहीं है या पहुँच योग्य नहीं है।",
      PAGE_NOT_FOUND_HEAD: "404 पृष्ठ नहीं मिला",
      SEEN_IT_ALL_MSG: "वाह! आपने यह सब देखा है",
      SEARCH_AGAIN_HEAD: "फिर से खोजो!",
      SEARCH: "खोज",
      SEARCH_AGAIN_MSG: "इस खोज के लिए कोई परिणाम उपलब्ध नहीं है।",
      EDIT_ORDER: "आदेश संपादित करें",
      CONFIRM_RETURN: "वापसी की पुष्टि करें",
      REASON_FOR_RETURN: "वापसी की वजह",
      CONFIRM_REPLACEMENT: "प्रतिस्थापन की पुष्टि करें",
      REASON_FOR_REPLACEMENT: "प्रतिस्थापन का कारण",
      RETURN: "वापसी",
      REPLACE: "बदलने के",
      ORDER_CANCELATION: "आदेश रद्द करना",
      CONFIRM_CANCELATION: "रद्द करने की पुष्टि करें",
      REASON_FOR_CANCELATION: "रद्द करने का कारण",
      CANCEL_THIS_ORDER: "इस आदेश को रद्द करें",
      PLEASE_ENTER_A_REASON: "कृपया कोई कारण दर्ज करें",
      ORDER_CANCEL_WARNING:
        "इस आदेश को रद्द करने से आप इस आदेश में अप्रेषित वस्तुओं पर प्रचार ऑफ़र और/या कैशबैक खो सकते हैं।",
      PREVIOUS_ORDER_TOTAL: "पिछला आदेश कुल",
      ITEMS_RETURNED: "आइटम लौटाए गए",
      REFUND_WARNING:
        "धनवापसी प्राप्त करने के लिए कृपया 7-14 कार्यदिवस प्रतीक्षा करें।",
      CART_UPDATE_WARNING_MESSAGE:
        "कार्ट को अपडेट करने से कूपन कोड निकल जाएगा। क्या आप अभी भी जारी रखना चाहते हैं?",
      ARE_YOU_SURE: "क्या आपको यकीन है?",
      REMOVE_FAV_WARNING: "क्या आप इस आइटम को हटाना चाहते हैं?",
      NO_DETAILS_AVAILABLE: "कोई विवरण उपलब्ध नहीं है",
      NO_PRODUCT_FOUND: "कोई उत्पाद नहीं मिला",
      UPDATE_CART: "कार्ट अपडेट करें",
      NO_DESCRIPTION_AVAILABLE: "कोई विवरण उपलब्ध नहीं",
      FREQUENTLY_BROUGHT_TOGETHER: "अक्सर साथ लाया जाता है",
      EDIT_ADDRESS: "पता संपादित करें",
      NO_ADDRESS_FOUND: "कोई पता नहीं मिला",
      SHOP_MORE_FOR_NOTIFICATION:
        "कुछ बेहतरीन नोटिफिकेशन देखने के लिए और खरीदारी करें :)",
      NO_NOTIFICATIONS: "सारी जानकारी प्राप्त!",
      NO_ACTIVE_ORDERS_HEADING: "कोई सक्रिय आदेश नहीं",
      NO_COMPLETED_ORDERS_HEADING: "कोई पूर्ण आदेश नहीं",
      NO_ORDERS_DESCRIPTION: "उन्हें यहां देखने के लिए एक आदेश बनाएं",
      SELECT_LANGUAGE: "भाषा का चयन करें",
      INAAM_REDEEM_MSG:
        "भुनाए गए इनाम बिंदुओं के अनुरूप कूपन स्वचालित रूप से ऑर्डर पर लागू हो जाएगा। इस आदेश के लिए भुनाया जा सकने वाला अधिकतम इनाम बिंदु होगा",
      REDEEM_INAAM: "इनाम को भुनाएं",
      REDEEM_POINTS: "की छूट भुनाएं",
      INAAM_POINTS_AVAILABLE: "इनाम अंक उपलब्ध",
      REDEEM: "मोचन करना",
      ALL_CATEGORIES: "सब वर्ग",
      ACCOUNT: "लेखा",
      VIEW_ORDER_STATUS: "आदेश की स्थिति देखें",
      VAT: "वैट",
      MY_CART: "मेरी कार्ट",
      ORDER_IS_PENDING: "आदेश लंबित है",
      ORDER_IS_BEING_PICKED: "आदेश उठाया जा रहा है",
      ORDER_PICKED: "आदेश चुना गया",
      ORDER_IS_BEING_PACKED: "आदेश पैक किया जा रहा है",
      ORDER_PACKED: "ऑर्डर पैक",
      PAYMENT_REVIEW: "भुगतान की समीक्षा",
      PAYMENT_PENDING: "भुगतान लंबित",
      ORDER_IS_BEING_PROCESSED: "ऑर्डर को संसाधित किया जा रहा है",
      ORDER_IS_ON_HOLD: "आदेश होल्ड पर है",
      PAYMENT_FAILED: "भुगतान असफल हुआ",
      ORDER_CLOSED: "आदेश बंद",
      SUSPECTED_FRAUD: "संदिग्ध धोखाधड़ी",
      READY_FOR_DELIVERY: "पहुँचाने के लिए तैयार",
      ON_THE_WAY: "रास्ते में",
      DELIVERED: "पहुंचा दिया",
      RETURN_INITIATED: "वापसी शुरू",
      RETURN_COLLECTED: "वापसी एकत्रित",
      ORDER_PARTIALLY_RETURNED: "आदेश आंशिक रूप से लौटा",
      ORDER_STATUS_UNKNOWN: "आदेश की स्थिति अज्ञात",
      MADE_A_MISTAKE: "एक गलती की?",
      EDIT_THIS_ORDER: "इस आदेश को संपादित करें",
      PAYMENT_INCOMPLETE: "भुगतान अधूरा",
      HAVING_PROBLEM: "समस्या हो रही है?",
      RETURN_ITEMS: "सामान वापस करे",
      DELIVERED_ON: "सौंप दिया",
      DELIVERY_TIME_NOT_AVAILABLE: "डिलीवरी का समय उपलब्ध नहीं है",
      DELIVERY_ON: "डिलीवरी चालू",
      POINTS: "अंक",
      POINTS_NOT_AVAILABLE: "अंक उपलब्ध नहीं",
      ARE_YOU_SURE_TO_LOGOUT: "क्या आप निश्चित रूप से लॉग आउट कर रहे हैं?",
      ARE_YOU_SURE_ABOUT_DELETING_ADDRESS:
        "क्या आप इस पते को हटाने के बारे में सुनिश्चित हैं?",
      REMOVE_THIS_PRODUCT: "इस उत्पाद को निकालें?",
      REMOVE_THIS_PRODUCT_WARNING_MSG:
        "इस वस्तु को खरीदने के लिए आवश्यक न्यूनतम मात्रा:",
      DO_YOU_WANT_TO_REMOVE_THIS_COUPON: "क्या आप इस कूपन को हटाना चाहते हैं?",
      COUPON_REMOVAL_ALERT_MSG:
        "इस कूपन को हटाने से आप प्रचार ऑफ़र और/या इस आदेश पर लागू कैशबैक खो सकते हैं।",
      DO_YOU_WANT_TO_EDIT_THIS_ORDER:
        "क्या आप इस आदेश को संपादित करना चाहते हैं?",
      HAVE_A_REFFERAL_CODE: "क्या आपके पास रेफ़रल कोड है?",
      REFERRAL_CODE: "रेफरल कोड",
      YOUR_HAVE_SIGNED_IN: "आपने साइन इन किया है।",
      SIGN_IN_SUCCESS_MSG: "आपने सफलतापूर्वक एक खाता बना लिया है।",
      WELCOME_TO: "में स्वागत",
      NESTO_ONLINE_SHOPPING: "नेस्टो ऑनलाइन शॉपिंग",
      WELCOME_BACK: "वापसी पर स्वागत है",
      WELCOME_BACK_MSG:
        "खरीदारी का भरपूर आनंद लेने के लिए, आइए आपको साइन इन करते हैं।",
      GET_OTP: "ओटीपी प्राप्त करें",
      GETTING_STARTED: "शुरू करना",
      CREATE_AN_ACCOUNT_TO_CONTINUE: "जारी रखने के लिए एक खाता बनाएं।",
      OTP_AUTHENTICATION: "ओटीपी प्रमाणीकरण",
      OTP_SENT_TO: "ओटीपी भेजा गया",
      DIDNT_RECEIVE_CODE: "कोड प्राप्त नहीं हुआ?",
      RESEND: "फिर से भेजें",
      CONTINUE: "जारी रखना",
      SELECT: "Select",
    },
    alignment: "RTL",
  },
];
export default locale;
