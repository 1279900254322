import React, { useState, useEffect, useContext } from "react";
import box from "../../assets/images/placeholder.svg";
import shipping from "../../assets/images/shipping.svg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.scss";
import "./ProductDetails.css";
import {
  getProduct,
  addToCart,
  removeFromWishList,
  addToWishList,
  getCategoryName,
  updateToCart,
  deleteFromCart,
  getBestSellers,
} from "../../api";
import { AppContext } from "../../context/AppContext";
import { IMAGE_BASE_URL } from "../../api/config";
import EmptyData from "../../components/EmptyData";
import emptyFavs from "../../assets/images/favs-empty.svg";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import _ from "underscore";
import { Modal } from "react-bootstrap";
import ReactImageMagnify from "react-image-magnify";
import MainLoader from "../../components/MainLoader";
import { FaMinus, FaPlus } from "react-icons/fa";
import HorizontalProductList from "../../components/HorizontalProductList/HorizontalProductList";
import { confirmAlert } from "react-confirm-alert";
import TagManager from "react-gtm-module";
import Loader from "react-loader-spinner";

function ProductDetails(props) {
  const {
    cartId,
    isLoggedIn,
    setShowLogin,
    addToCartContext,
    updateCartContext,
    removeFromCartContext,
    wishlistData,
    addToFavourites,
    removeFromFavourites,
    currentStore,
    setToastData,
    setShowToast,
    setBreadcrumbs,
    cartData,
  } = useContext(AppContext);
  const [productSrc, setProductSrc] = useState();
  const [imageArray, setImageArray] = useState([]);
  const [productData, setProductData] = useState();
  const [quantity, setQuantity] = useState(1);
  const [isWishlist, setisWishlist] = useState(false);
  const [activeTab, setActiveTab] = useState(true);
  const [productLoading, setProductLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noProductFound, setNoProductFound] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [discount, setDiscount] = useState(0);

  const [prodCat, setProdCat] = useState([]);
  const [bestSellers, setBestSellers] = useState([]);

  useEffect(() => {
    getBestSellers(currentStore?.store_id).then((res) => {
      let tempData = [];
      res?.map((item1) => {
        if (item1.type_id === "simple" && parseFloat(item1.price) !== 0) {
          tempData.push(item1);
        } else
          item1?.configurable_child_data?.map((item2) => {
            if (item2.type_id === "simple" && parseFloat(item2.price) !== 0)
              tempData.push(item2);
          });
      });
      setBestSellers(tempData);
    });
  }, []);

  useEffect(() => {
    let mDiscount = productData?.cross_price
      ? ((productData?.cross_price - productData?.tax_included_special_price) /
          productData?.cross_price) *
        100
      : ((productData?.tax_included_price -
          productData?.tax_included_special_price) /
          productData?.tax_included_price) *
        100;
    if (!isFinite(mDiscount)) {
      mDiscount = 0;
    }
    setDiscount(mDiscount);
  }, [productData]);

  useEffect(() => {
    if (currentStore) {
      setProdCat([]);
      setProductLoading(true);

      getProduct(props.match.params.sku, currentStore?.store_id)
        .then((res) => {
          window.gtag("event", "view_item", {
            currency: "AED",
            value: parseFloat(res?.tax_included_price || res?.price).toFixed(2),
            items: [
              {
                item_id: res?.sku,
                item_name: res?.name,
                currency: "AED",
                index: 0,
                price: res?.price,
              },
            ],
          });
          setProductData(res);
          res?.category_ids
            // ?.filter((catid) => catid >= 1604 && catid <= 1748) //staging
            ?.filter(
              (catid) =>
                catid >=
                  (process?.env?.REACT_APP_ENV === "production"
                    ? 2586
                    : 1604) &&
                catid <=
                  (process?.env?.REACT_APP_ENV === "production" ? 2726 : 1748)
            )
            ?.map((catId, index) => {
              getCategoryName(catId).then((catData) => {
                setProdCat((oldArray) => [
                  ...oldArray,
                  {
                    id: catData?.level,
                    title: catData?.name,
                    path: "/categories/" + catId,
                  },
                ]);
              });
            });
          return res;
        })
        .then((pdRes) => {
          let tempImageArr = [];
          /**Coverting object of objects to array of objects. We are getting an object of objects from api for media_gallery */
          const tempArrayOfImg = Object.keys(pdRes?.media_gallery?.images).map(
            (key) => {
              return pdRes?.media_gallery?.images[key];
            }
          );
          tempArrayOfImg?.forEach((i) => {
            tempImageArr.push(IMAGE_BASE_URL + "/catalog/product" + i.file);
          });
          setImageArray([...tempImageArr]);
          setProductSrc(
            pdRes?.image
              ? IMAGE_BASE_URL + "/catalog/product" + pdRes?.image
              : box
          );
        })
        .catch((err) => {
          setNoProductFound(true);
          console.log(err.message);
        })
        .finally(() => {
          setTimeout(() => {
            setProductLoading(false);
          }, 1500);
        });
    }
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    // setImageArray([]);
  }, [props.match.params.sku]);
  useEffect(() => {
    setQuantity(
      cartData?.filter((item) => item?.sku === productData?.sku)[0]?.qty || 1
    );
  }, [cartData, productData]);

  useEffect(() => {
    setBreadcrumbs([
      { title: "Home", path: "/" },
      ..._.sortBy(prodCat, "id"),
      { title: productData?.name },
    ]);
  }, [prodCat, productData?.name]);
  const cartDataLogger = (eventName, data, qty) => {
    if (eventName === "addToCart") {
      window.gtag("event", "add_to_cart", {
        currency: "AED",
        value: parseFloat(
          productData?.tax_included_price || productData?.price
        ).toFixed(2),
        items: [
          {
            item_id: productData?.sku,
            item_name: productData?.name,
            currency: "AED",
            index: 0,
            price: productData?.price,
            quantity: productData?.qty,
          },
        ],
      });
    } else if (eventName === "removeFromCart") {
      window.gtag("event", "remove_from_cart", {
        currency: "AED",
        value: parseFloat(
          props?.item?.tax_included_price || props?.item?.price
        ).toFixed(2),
        items: [
          {
            item_id: props?.item?.sku,
            item_name: props?.item?.name,
            currency: "AED",
            index: 0,
            price: props?.item?.price,
            quantity: props?.item?.qty,
          },
        ],
      });
    }
  };
  const addToCartFn = (tempCartData) => {
    addToCart(currentStore?.store_code, tempCartData)
      .then((res) => {
        cartDataLogger("addToCart", res, res?.qty);
        addToCartContext(res?.item_id, productData, res?.qty);
        setShowToast(true);
        setToastData({
          type: "success",
          text: "Product added to cart",
        });
        // setIsCartLoading(false);
      })
      .catch((e) => {
        setShowToast(true);
        setToastData({
          type: "error",
          text: e.message,
        });
      })
      .finally(() => setLoading(false));
  };

  const updateCartFn = (updatetoCart, tempCartData) => {
    updateToCart(
      currentStore?.store_code,
      updatetoCart[0]?.item_id,
      tempCartData
    )
      .then((res) => {
        cartDataLogger("addToCart", res, res?.qty);
        updateCartContext(res?.item_id, res?.qty);
        setShowToast(true);
        setToastData({
          type: "success",
          text: "Quantity Updated to cart",
        });
      })
      .catch((e) => {
        setShowToast(true);
        setToastData({
          type: "error",
          text: e.message,
        });
      })
      .finally(() => setLoading(false));
  };

  const deleteFromCartFn = (updatetoCart) => {
    deleteFromCart(currentStore?.store_code, updatetoCart[0]?.item_id)
      .then((res) => {
        if (res) {
          cartDataLogger("removeFromCart", productData, updatetoCart[0]?.qty);
          removeFromCartContext(updatetoCart[0]?.item_id);
          setShowToast(true);
          setToastData({
            type: "success",
            text: "Product removed from cart",
          });
        }
      })
      .catch((e) => {
        setShowToast(true);
        setToastData({
          type: "error",
          text: e.message,
        });
      })
      .finally(() => setLoading(false));
  };

  const handleCartFn = () => {
    const tempCartData = {
      cartItem: {
        quote_id: cartId,
        sku: productData.sku,
        qty: quantity,
      },
    };
    if (isLoggedIn) {
      // setIsCart(true);
      setLoading(true);
      let updatetoCart = cartData?.filter(
        (item) => item?.sku === productData?.sku
      );

      if (updatetoCart?.length === 0) {
        addToCartFn(tempCartData);
      } else {
        if (updatetoCart[0]?.qty === quantity) {
          deleteFromCartFn(updatetoCart);
        } else {
          updateCartFn(updatetoCart, tempCartData);
        }
        // if (tempCartData?.cartItem?.qty === updatetoCart[0]?.qty) {
        //   setShowToast(true);
        //   setToastData({
        //     type: "info",
        //     text: "Quantity is same as that of the cart!",
        //   });
        //   return 0;
        // }
      }
    } else {
      setShowLogin(true);
    }
  };

  const handleFavorite = (item) => {
    if (isLoggedIn) {
      if (isWishlist) {
        confirmAlert({
          customUI: ({ onClose }) => {
            return (
              <div className="react-confirm-alert-body confirm-popup-button-body">
                <h2 className="fw-700">{"Do you want to remove this item?"}</h2>
                <div className="btn-group d-flex justify-content-end">
                  <button
                    className={"confirm-popup-button confirm-button-update"}
                    onClick={() => {
                      removeFromWishList(
                        item?.entity_id,
                        currentStore?.store_code
                      )
                        .then((res) => {
                          if (res) {
                            removeFromFavourites(item?.entity_id);
                            setisWishlist(!isWishlist);
                            setShowToast(true);
                            setToastData({
                              type: "success",
                              text: "Removed from Favourite",
                            });
                          } else {
                            setShowToast(true);
                            setToastData({
                              type: "error",
                              text: "Something went wrong! Failed to remove from Favourite",
                            });
                          }
                        })
                        .catch((e) => {
                          setShowToast(true);
                          setToastData({
                            type: "error",
                            text: e.message,
                          });
                        });
                      onClose();
                    }}
                  >
                    {"Yes"}
                  </button>
                  <button
                    className={"cancel-popup-button cancel-button-update"}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    No
                  </button>
                </div>
              </div>
            );
          },
          closeOnClickOutside: true,
        });
      } else {
        addToWishList(item?.entity_id, currentStore?.store_code)
          .then((res) => {
            if (res) {
              window.gtag("event", "add_to_wishlist", {
                currency: "AED",
                value: parseFloat(
                  productData?.tax_included_price || productData?.price
                ).toFixed(2),
                items: [
                  {
                    item_id: productData?.sku,
                    item_name: productData?.name,
                    currency: "AED",
                    index: 0,
                    price: productData?.price,
                  },
                ],
              });
              addToFavourites(item);
              setisWishlist(!isWishlist);
              setShowToast(true);
              setToastData({
                type: "success",
                text: "Added to Favourite",
              });
            } else {
              setShowToast(true);
              setToastData({
                type: "error",
                text: "Something went wrong! Failed to add to Favourite",
              });
            }
          })
          .catch((e) => {
            setShowToast(true);
            setToastData({
              type: "error",
              text: e.message,
            });
          });
      }
    } else setShowLogin(true);
  };

  useEffect(() => {
    setisWishlist(
      wishlistData?.filter((item) => item.entity_id === productData?.entity_id)
        .length > 0
        ? true
        : false
    );
  }, [wishlistData, productData]);

  useDocumentTitle(
    productData?.name
      ? productData?.name + " - Nesto Online Shopping"
      : "Product - Nesto Online Shopping"
  );
  return (
    <>
      {productLoading ? (
        <MainLoader innerClassName="product-loader-animation" />
      ) : (
        <>
          {noProductFound ? (
            <div className="empty-product">
              <EmptyData
                picture={emptyFavs}
                heading={"Empty Product"}
                description={"Product details not available"}
              />
            </div>
          ) : (
            <div className="container">
              <div className="row">
                <div className="my-lg-2 my-1 col-lg-4 col-md-8 order-1">
                  <div className="product-list-details-imgbox p-3 text-center">
                    <div
                      className="multiple-image-wrapper"
                      onClick={() => setModalShow(true)}
                    >
                      {productSrc && (
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: productData?.name,
                              isFluidWidth: true,
                              src: productSrc,
                            },
                            largeImage: {
                              src: productSrc,
                              width: 1500,
                              height: 1500,
                            },
                          }}
                          className="product-list-img-wrap"
                          imageClassName="product-list-img-mob"
                          enlargedImagePosition="over"
                          enlargedImageContainerClassName="col-md-12"
                          enlargedImageContainerStyle={{
                            zIndex: 1000,
                            height: "100%",
                            width: "100%",
                          }}
                          enlargedImageStyle={{
                            height: "300px",
                            width: "600px",
                          }}
                        />
                      )}
                    </div>
                    <div className="my-2">
                      <Swiper
                        spaceBetween={10}
                        slidesPerView={"auto"}
                        centeredSlides={true}
                        slideToClickedSlide={true}
                        onSlideChangeTransitionStart={(swiper) => {
                          setProductSrc(
                            swiper.slides[swiper.activeIndex].children[0].src
                          );
                        }}
                        onSwiper={(swiper) => {
                          setProductSrc(
                            swiper?.slides[swiper.activeIndex]?.children[0]?.src
                          );
                        }}
                      >
                        {imageArray?.map((imageItem, index) => {
                          return (
                            <SwiperSlide
                              key={`${index}-${imageItem}`}
                              onClick={() => {
                                imageItem
                                  ? setProductSrc(imageItem)
                                  : setProductSrc(box);
                              }}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#fff",
                              }}
                            >
                              <img
                                className="img-fluid"
                                src={imageItem ? imageItem : box}
                                alt="product"
                              />
                            </SwiperSlide>
                          );
                        })}

                        {/* <SwiperSlide
                          key={`${"index"}-${productSrc}`}
                          onClick={() => {
                            productSrc
                              ? setProductSrc(productSrc)
                              : setProductSrc(box);
                          }}
                        >
                          <img
                            className="img-fluid"
                            src={productSrc ? productSrc : box}
                            alt="product"
                          />
                        </SwiperSlide> */}
                        {/*    <SwiperSlide
                              onClick={() => {
                                productData?.small_image
                                  ? setProductSrc(
                                      IMAGE_BASE_URL +
                                        "/catalog/product" +
                                        productData?.small_image
                                    )
                                  : setProductSrc(box);
                              }}
                            >
                              <img
                                className="img-fluid"
                                src={
                                  productData?.small_image
                                    ? IMAGE_BASE_URL +
                                      "/catalog/product" +
                                      productData?.small_image
                                    : box
                                }
                                alt="product"
                              />
                            </SwiperSlide>
                        */}
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div className="my-lg-2 my-1 col-lg-5 order-lg-2 col-md-12 order-md-3 order-2">
                  <div className="border-line-all container-radius">
                    <div className="p-lg-3 py-1 px-3 border-line">
                      <p className="product-details-label m-0">
                        {productData?.name}
                      </p>
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="product-list label-green m-0">
                          {productData?.tax_included_special_price > 0 ? (
                            <>
                              <span className="price-strike my-0">
                                {"AED " +
                                  parseFloat(
                                    productData?.cross_price
                                      ? productData?.cross_price
                                      : productData?.tax_included_price
                                  ).toFixed(2)}
                              </span>
                              <span>
                                {"AED " +
                                  parseFloat(
                                    productData?.tax_included_special_price || 0
                                  ).toFixed(2) || "null"}
                              </span>
                            </>
                          ) : (
                            <span>
                              {"AED " +
                                parseFloat(
                                  productData?.tax_included_price || 0
                                ).toFixed(2) || "null"}
                            </span>
                          )}
                        </p>

                        {/* <p className="d-flex align-items-center rating-tag m-0">4.5</p> */}
                      </div>
                      <div className="d-flex align-items-center">
                        {productData?.tax_included_special_price > 0 &&
                          productData?.is_salable === 1 && (
                            <p className="discount-tag my-0 mr-2">
                              {discount.toFixed() + "% OFF"}
                            </p>
                          )}

                        <p className="wallet-tag m-0 d-flex align-items-center">
                          {(productData?.special_price
                            ? productData?.special_price / 5
                            : productData?.price / 5
                          ).toFixed(2) + " INAAM POINTS"}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex flex-column-reverse flex-sm-column">
                      <div className="d-none p-lg-3 py-1 px-3 border-line d-md-flex justify-content-between align-items-center">
                        <div>
                          <p className="m-0 product-details-small-label">
                            {cartData?.filter(
                              (item) => item?.sku === productData?.sku
                            )?.length === 0
                              ? "Quantity:"
                              : "Quantity in Cart:"}
                          </p>
                          <div
                            className={
                              "d-inline-flex align-items-center justify-content-center product-details-qty my-1" +
                              (productData?.is_salable !== 1 && " out-of-stock")
                            }
                          >
                            <FaMinus
                              fill="white"
                              className="c-pointer mr-3"
                              onClick={() => {
                                setQuantity((qty) =>
                                  qty > 1 ? quantity - 1 : 1
                                );
                              }}
                            />
                            <p className="m-0 product-details-qty-num">
                              {quantity}
                            </p>
                            <FaPlus
                              fill="white"
                              className="c-pointer ml-3"
                              onClick={() => {
                                if (
                                  productData?.salable_qty[0]?.manage_stock &&
                                  productData?.salable_qty[0]?.qty <= quantity
                                ) {
                                  setShowToast(true);
                                  setToastData({
                                    type: "info",
                                    text: `Only ${productData?.salable_qty[0]?.qty} item(s) available in stock`,
                                  });
                                } else setQuantity(quantity + 1);
                              }}
                            />
                          </div>
                          {/* <p className="m-0 product-details-small-label">Flavours:</p>
                    <div className="d-inline-flex align-items-center justify-content-center my-3">
                      <div
                        className={
                          "product-details-flavour mr-2" +
                          (isFlavour === 0 ? " active" : "")
                        }
                        onClick={() => setIsFlavour(0)}
                      >
                        <div className="product-details-flavour-img"></div>
                        <p className="product-details-flavour-label mb-0 mt-1 text-center">
                          Blue
                        </p>
                      </div>
                      <div
                        className={
                          "product-details-flavour mr-2" +
                          (isFlavour === 1 ? " active" : "")
                        }
                        onClick={() => setIsFlavour(1)}
                      >
                        <div className="product-details-flavour-img"></div>
                        <p className="product-details-flavour-label mb-0 mt-1 text-center">
                          Yellow
                        </p>
                      </div>
                    </div>
                    <p className="m-0 product-details-small-label">SIZE:</p>
                    <div className="d-inline-flex align-items-center justify-content-center my-3">
                      <button
                        className={
                          "product-details-size mr-2 click-anim" +
                          (isSize === 0 ? " active" : "")
                        }
                        onClick={() => setisSize(0)}
                      >
                        250g
                      </button>
                      <button
                        className={
                          "product-details-size mr-2 click-anim" +
                          (isSize === 1 ? " active" : "")
                        }
                        onClick={() => setisSize(1)}
                      >
                        500g
                      </button>
                      <button
                        className={
                          "product-details-size mr-2 click-anim" +
                          (isSize === 2 ? " active" : "")
                        }
                        onClick={() => setisSize(2)}
                      >
                        1kg
                      </button>
                    </div> */}
                        </div>
                        <div>
                          {productData?.salable_qty &&
                          0 < productData?.salable_qty[0]?.qty &&
                          productData?.salable_qty[0]?.qty < 10 ? (
                            <div className="text-danger fw-900 ">{`Hurry! only ${productData?.salable_qty[0]?.qty} left`}</div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="d-none p-lg-3 py-2 px-3 d-md-flex align-items-center">
                      <button
                        className={
                          "product-details-button details-cart mr-3 click-anim" +
                          (productData?.is_salable !== 1
                            ? " out-of-stock"
                            : cartData?.filter(
                                (item) => item?.sku === productData?.sku
                              )?.length === 0
                            ? " btn-outline-success"
                            : cartData?.filter(
                                (item) => item?.sku === productData?.sku
                              )[0]?.qty === quantity
                            ? " btn-outline-danger"
                            : " btn-outline-success")
                        }
                        disabled={loading}
                        onClick={handleCartFn}
                      >
                        {loading ? (
                          <Loader
                            type="ThreeDots"
                            color={
                              cartData?.filter(
                                (item) => item?.sku === productData?.sku
                              )?.length !== 0
                                ? "red"
                                : "mediumseagreen"
                            }
                            height={17}
                            width={28}
                          />
                        ) : productData?.is_salable !== 1 ? (
                          "Out of Stock"
                        ) : cartData?.filter(
                            (item) => item?.sku === productData?.sku
                          )?.length === 0 ? (
                          "Add to Cart"
                        ) : cartData?.filter(
                            (item) => item?.sku === productData?.sku
                          )[0]?.qty === quantity ? (
                          "Remove from Cart"
                        ) : (
                          "Update Cart"
                        )}
                      </button>
                      {/* <button className="product-details-button details-buy mr-3 click-anim">BUY NOW</button> */}
                      <button
                        className={
                          "product-details-wishlist click-anim" +
                          (isWishlist ? " active" : "")
                        }
                        onClick={() => handleFavorite(productData)}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 27 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M24.1172 13.2094L13.5018 24.2812L2.88325 13.2094C2.22854 12.5406 1.70769 11.7384 1.35191 10.8508C0.99613 9.96318 0.812744 9.00841 0.812744 8.04371C0.812744 7.07901 0.99613 6.12424 1.35191 5.23661C1.70769 4.34899 2.22854 3.54678 2.88325 2.87806C4.20312 1.49451 5.98301 0.71875 7.83753 0.71875C9.69204 0.71875 11.4719 1.49451 12.7918 2.87806L13.5018 3.61529L14.2118 2.87806C15.5312 1.49472 17.3106 0.719049 19.1645 0.719049C21.0185 0.719049 22.7979 1.49472 24.1172 2.87806C24.772 3.54678 25.2928 4.34899 25.6486 5.23661C26.0044 6.12424 26.1877 7.07901 26.1877 8.04371C26.1877 9.00841 26.0044 9.96318 25.6486 10.8508C25.2928 11.7384 24.772 12.5406 24.1172 13.2094Z"
                            fill="#00983D"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="my-lg-2 my-1 col-lg-3 order-lg-3 col-md-4 order-md-2 order-4">
                  <div className="px-4 py-2 border-line-all container-radius">
                    {/* <img className="img-fluid w-100" src={shipping}></img> */}
                    {/* <div className="shipping-points d-flex align-items-center distance py-3 border-line">Free Delivery for orders with AED 30</div> */}
                    <div className="shipping-points d-flex align-items-center express py-3 border-line">
                      Products delivered to your doorstep
                    </div>
                    <div className="shipping-points d-flex align-items-center message py-3">
                      Contact us at anytime
                    </div>
                    {/* <div className="shipping-points d-flex align-items-center coupon py-3">Offer : Get two for the price of one</div> */}
                  </div>
                </div>
                <div className="my-lg-2 my-1 col-12 order-3 nav-container">
                  <div className="col nav-tab pt-lg-2 pt-1 ">
                    <ul className="nav nav-bars c-pointer">
                      <li
                        className={
                          "px-5 details-tab " +
                          (activeTab ? "active active-details-tab" : "")
                        }
                      >
                        <a
                          onClick={() => {
                            setActiveTab(true);
                          }}
                        >
                          Details
                        </a>
                      </li>
                      <li
                        className={
                          "px-5 details-tab " +
                          (activeTab ? "" : "active active-details-tab")
                        }
                      >
                        {/* <a
                    onClick={() => {
                      setActiveTab(false);
                    }}
                  >
                    Nutrient Information
                  </a> */}
                      </li>
                    </ul>
                  </div>

                  <div className="col tab-content pt-3">
                    <div
                      className={
                        "tab-pane tab-description " +
                        (activeTab ? "active" : "")
                      }
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: productData?.description,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="my-lg-2 my-1 col-12 order-5">
                  <HorizontalProductList
                    title={"Bestsellers"}
                    swiperName={"bestseller-swiper"}
                    data={bestSellers}
                    // key={index}
                    // link={item.link}
                    // ctaData={{ link: item?.cta_link, text: item?.cta_text }}
                    loading={true}
                  />
                </div>
              </div>

              <ImagePreview
                show={modalShow}
                onHide={() => setModalShow(false)}
                productData={productData}
                imageArray={imageArray}
                productSrc={productSrc}
              />
            </div>
          )}
        </>
      )}
      <div className="pdp-mob-addcart d-block d-md-none">
        <div className="d-flex align-items-center justify-content-center">
          {/* <p className="m-0 product-details-small-label">{cartData?.filter(item => item?.sku === productData?.sku)?.length === 0 ? "Quantity:" : "Quantity in Cart:"}</p> */}
          <div
            className={
              "d-inline-flex align-items-center justify-content-center product-details-qty my-1" +
              (productData?.is_salable !== 1 && " out-of-stock")
            }
          >
            <FaMinus
              fill="white"
              className="c-pointer mr-3"
              onClick={() => {
                setQuantity((qty) => (qty > 1 ? quantity - 1 : 1));
              }}
            />
            <p className="m-0 product-details-qty-num">{quantity}</p>
            <FaPlus
              fill="white"
              className="c-pointer ml-3"
              onClick={() => {
                if (
                  productData?.salable_qty[0]?.manage_stock &&
                  productData?.salable_qty[0]?.qty <= quantity
                ) {
                  setShowToast(true);
                  setToastData({
                    type: "info",
                    text: `Only ${productData?.salable_qty[0]?.qty} item(s) available in stock`,
                  });
                } else setQuantity(quantity + 1);
              }}
            />
          </div>
          <button
            className={
              "product-details-button details-cart mx-2 click-anim" +
              (productData?.is_salable !== 1
                ? " out-of-stock"
                : cartData?.filter((item) => item?.sku === productData?.sku)
                    ?.length === 0
                ? " btn-outline-success"
                : cartData?.filter((item) => item?.sku === productData?.sku)[0]
                    ?.qty === quantity
                ? " btn-outline-danger"
                : " btn-outline-success")
            }
            onClick={handleCartFn}
            disabled={loading}
          >
            {loading ? (
              <Loader
                type="ThreeDots"
                color={
                  cartData?.filter((item) => item?.sku === productData?.sku)
                    ?.length !== 0
                    ? "red"
                    : "mediumseagreen"
                }
                height={17}
                width={28}
              />
            ) : productData?.is_salable !== 1 ? (
              "Out of Stock"
            ) : cartData?.filter((item) => item?.sku === productData?.sku)
                ?.length === 0 ? (
              "Add to Cart"
            ) : cartData?.filter((item) => item?.sku === productData?.sku)[0]
                ?.qty === quantity ? (
              "Remove from Cart"
            ) : (
              "Update Cart"
            )}
          </button>
          <button
            className={
              "product-details-wishlist click-anim" +
              (isWishlist ? " active" : "")
            }
            onClick={() => handleFavorite(productData)}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 27 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.1172 13.2094L13.5018 24.2812L2.88325 13.2094C2.22854 12.5406 1.70769 11.7384 1.35191 10.8508C0.99613 9.96318 0.812744 9.00841 0.812744 8.04371C0.812744 7.07901 0.99613 6.12424 1.35191 5.23661C1.70769 4.34899 2.22854 3.54678 2.88325 2.87806C4.20312 1.49451 5.98301 0.71875 7.83753 0.71875C9.69204 0.71875 11.4719 1.49451 12.7918 2.87806L13.5018 3.61529L14.2118 2.87806C15.5312 1.49472 17.3106 0.719049 19.1645 0.719049C21.0185 0.719049 22.7979 1.49472 24.1172 2.87806C24.772 3.54678 25.2928 4.34899 25.6486 5.23661C26.0044 6.12424 26.1877 7.07901 26.1877 8.04371C26.1877 9.00841 26.0044 9.96318 25.6486 10.8508C25.2928 11.7384 24.772 12.5406 24.1172 13.2094Z"
                fill="#00983D"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
}

const ImagePreview = (props) => {
  const [productSrc, setProductSrc] = useState();
  useEffect(() => {
    setProductSrc(props?.productSrc);
  }, []);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <div className="fs-16 px-4 faded-color">Images</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="py-0">
          <div className="position-relative d-flex justify-content-center image-viewer">
            {props?.imageArray?.length === 0 && (
              <div className="no-image-preview">No Image Preview available</div>
            )}
            <img
              src={productSrc}
              alt="product"
              height={"auto"}
              style={{ maxWidth: "380px", width: "100%", objectFit: "contain" }}
            />
          </div>
          <div>
            <div className="fs-14 fw-700 py-3">{props?.productData?.name}</div>
            <div className="row py-3">
              {props?.imageArray?.length > 0 ? (
                props?.imageArray?.map((imageItem) => {
                  return (
                    <div key={`${imageItem}`} className="col-sm-2 col-4 mb-2">
                      <img
                        className="c-pointer border solid rounded p-2"
                        src={imageItem ? imageItem : box}
                        onClick={() => {
                          setProductSrc(imageItem ? imageItem : box);
                        }}
                        alt="product"
                        width={"100%"}
                      />
                    </div>
                  );
                })
              ) : (
                <div className="container">No Image Preview available</div>
              )}
            </div>

            {/* <div className="row py-3">
              {productSrc ? (
                <div key={`${productSrc}`} className="col-sm-2 col-4 mb-2">
                  <img
                    className="c-pointer border solid rounded p-2"
                    src={productSrc ? productSrc : box}
                    onClick={() => {
                      setProductSrc(productSrc ? productSrc : box);
                    }}
                    alt="product"
                    width={"100%"}
                  />
                </div>
              ) : (
                <div className="container">No Image Preview available</div>
              )}
            </div> */}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ProductDetails;
