import React from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  inaamAndroidLink,
  inaamIosLink,
  nestoAndroidLink,
  nestoIosLink,
} from "../../constants/downloadLinks";
import { getMobileOperatingSystem } from "../../utils/getMobileOperatingSystem";

export const DownloadApp = () => {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);

  useEffect(() => {
    const handleRedirect = () => {
      const appName = query.get("appName");
      const os = getMobileOperatingSystem();

      if (appName === "nesto") {
        if (os === "windows" || "android") {
          window.location.href = nestoAndroidLink;
          return null;
        }
        if (os === "ios") {
          window.location.href = nestoIosLink;
          return null;
        }
      }

      if (appName === "inaam") {
        if (os === "windows" || "android") {
          window.location.href = inaamAndroidLink;
          return null;
        }
        if (os === "ios") {
          window.location.href = inaamIosLink;
          return null;
        }
      }

      return history.push("/");
    };
    handleRedirect();
  }, []);

  return <div>DownloadApp</div>;
};
