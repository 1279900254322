import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { getBestSellers } from "../../api";
import { AppContext } from "../../context/AppContext";
import ItemListing from "../../components/ItemListing";
import EmptyData from "../../components/EmptyData";
import emptycart from "../../assets/images/empty-cart.svg";
import "./Cart.css";
import HorizontalProductList from "../../components/HorizontalProductList/HorizontalProductList.js";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import TagManager from "react-gtm-module";
import { minimumPrice } from "../../constants/storeMinimum";

function Cart() {
  const {
    cartData,
    isLoggedIn,
    setShowToast,
    setToastData,
    setBreadcrumbs,
    setCartUpdated,
    currentStore,
    store,
    storePrices,
  } = useContext(AppContext);
  const [isDelete, setIsDelete] = useState();
  const [taxTotal, setTaxTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [bestSellers, setBestSellers] = useState([]);
  const [cartTotal, setCartTotal] = useState();

  let history = useHistory();
  useEffect(() => {
    setCartUpdated((old) => !old);
  }, []);

  useEffect(() => {
    let mSubTotal = 0;
    let mTaxTotal = 0;
    cartData?.map((item) => {
      mSubTotal += fixTwo(item?.qty * item?.price);
      mTaxTotal += fixTwo(
        fixTwo(fixTwo(item?.qty * item?.price) * item?.tax_percentage) / 100
      );
    });

    setCartTotal(mSubTotal + mTaxTotal);
  }, [cartData]);

  const handleCheckout = () => {
    if (cartData?.filter((x) => x.is_salable !== 1).length > 0) {
      setToastData({
        type: "error",
        text: "Out of stock item(s) in your cart",
      });
      setShowToast(true);
      return;
    }
    if (taxTotal + subTotal >= storePrices?.min_order_value) {
      // process?.env?.REACT_APP_ENV === "production" &&
      //   TagManager.dataLayer({
      //     dataLayerName: "dataLayer",
      //     dataLayer: {
      //       ecommerce: null,
      //     },
      //   });
      let prods = [];
      cartData?.map((i) => {
        prods.push({
          name: i?.name,
          id: i?.sku,
          price: i?.tax_included_price,
          brand: "",
          category: "",
          variant: "",
          quantity: i?.qty,
        });
      });
      // process?.env?.REACT_APP_ENV === "production" &&
      //   TagManager.dataLayer({
      //     dataLayerName: "dataLayer",
      //     dataLayer: {
      //       event: "checkout",
      //       ecommerce: {
      //         checkout: {
      //           actionField: { list: "cart", step: 1 },
      //           products: prods,
      //         },
      //       },
      //     },
      //   });
      window.gtag("event", "begin_checkout", {
        currency: "AED",
        value: parseFloat(cartTotal).toFixed(2),
        items: cartData.map((item, index) => ({
          item_id: item.sku,
          item_name: item.name,
          currency: "AED",
          index: index,
          price: item.price,
          quantity: item.qty,
        })),
      });
      history.push("/checkout");
    } else {
      setShowToast(true);
      setToastData({
        type: "error",
        text: `Minimum Cart Value should be AED ${storePrices?.min_order_value}`,
      });
    }
  };
  useEffect(() => {
    setBreadcrumbs([{ title: "Home", path: "/" }, { title: "Cart" }]);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getBestSellers(currentStore?.store_id).then((res) => {
      let tempData = [];
      res?.map((item1) => {
        if (item1.type_id === "simple" && parseFloat(item1.price) !== 0) {
          tempData.push(item1);
        } else
          item1?.configurable_child_data?.map((item2) => {
            if (item2.type_id === "simple" && parseFloat(item2.price) !== 0)
              tempData.push(item2);
          });
      });
      setBestSellers(tempData);
    });
  }, []);

  useEffect(() => {
    if (isDelete == undefined) return;
    if (!isLoggedIn) return;
  }, [isDelete]);
  const fixTwo = (x) => {
    let fix = Math.round(x * 100) / 100;
    return fix;
  };
  useEffect(() => {
    let mSubTotal = 0;
    let mTaxTotal = 0;
    cartData?.map((item) => {
      mSubTotal += fixTwo(item?.qty * item?.price);
      mTaxTotal += fixTwo(
        fixTwo(fixTwo(item?.qty * item?.price) * item?.tax_percentage) / 100
      );
    });

    setSubTotal(mSubTotal);
    setTaxTotal(mTaxTotal);
  }, [cartData]);
  useDocumentTitle("Cart - Nesto Online Shopping");
  return (
    <div className="container">
      {cartData?.length > 0 ? (
        <div className="cart-page py-5">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <ItemListing
                  type="cart"
                  isDelete={isDelete}
                  setIsDelete={setIsDelete}
                  data={cartData}
                />
              </div>
              <div className="col-md-4">
                <div className="border-line-all container-radius">
                  <p className="m-0 border-line p-3 faded-text text-center font-weight-bold">
                    ORDER SUMMARY
                  </p>
                  <div className="p-4 border-line">
                    <div className="d-flex justify-content-between mb-2">
                      <p className="m-0 paragraph-info">Sub Total</p>
                      <p className="m-0 paragraph-info font-weight-bold">
                        {`AED ${parseFloat(subTotal).toFixed(2)}`}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-2">
                      <p className="m-0 paragraph-info">Tax</p>
                      <p className="m-0 paragraph-info font-weight-bold">
                        {`AED ${parseFloat(taxTotal).toFixed(2)}`}
                      </p>
                    </div>
                  </div>
                  <div className="p-4 border-line">
                    <div className="d-flex justify-content-between mb-2">
                      <p className="bold-label m-0 d-flex flex-column">
                        Total
                        <span className="small">VAT included</span>
                      </p>
                      <p className="bold-label m-0">
                        {`AED ${parseFloat(subTotal + taxTotal).toFixed(2)}`}
                      </p>
                    </div>
                  </div>
                  <div className="p-4">
                    <button
                      onClick={handleCheckout}
                      className="w-100 primary-button rounded-button my-3 p-3 click-anim"
                    >
                      Go To Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty-product">
          <EmptyData
            picture={emptycart}
            heading={"Cart Empty"}
            description={"Add items to your cart to view them here"}
          />
        </div>
      )}
      <div>
        <HorizontalProductList
          title={"Bestsellers"}
          swiperName={"bestseller-swiper"}
          data={bestSellers}
          // key={index}
          // link={item.link}
          // ctaData={{ link: item?.cta_link, text: item?.cta_text }}
          loading={true}
        />
      </div>
    </div>
  );
}
export default Cart;
