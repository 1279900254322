import { useContext, useEffect, useState } from "react";
import {} from "../../api";
import AdBanner from "../../components/AdBanner/AdBanner";
import Carousel from "../../components/Carousel/Carousel";
import DealsView from "../../components/DealsView/DealsView";
import DealsView2 from "../../components/DealsView2/DealsView2";
import DynamicSectionList from "../../components/DynamicSectionList/DynamicSectionList";
import DynamicSectionSlider from "../../components/DynamicSectionSlider/DynamicSectionSlider";
import Grid from "../../components/Grid/Grid";
import GridView from "../../components/GridView/GridView";
import NoMarginBanner from "../../components/NoMarginBanner/NoMarginBanner";
import OfferBanner from "../../components/OfferBanner/OfferBanner";
import ThreeGridBanner from "../../components/ThreeBannerGrid/ThreeBannerGrid";
import TwoBannerGrid from "../../components/TwoBannerGrid/TwoBannerGrid";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import "./HomepageNew.css";

const MainPage = () => {
  const [homepageDesignData, setHomepageDesignData] = useState([]);
  const { appDesignData, getComponentDesignData, setBreadcrumbs } =
    useContext(AppContext);
  const { width } = useWindowSize();
  const [pageId, setPageId] = useState("");

  useEffect(() => {
    setBreadcrumbs([]);
  }, []);

  useEffect(() => {
    const getPageDesignData = async () => {
      const homepageData = await appDesignData?.data?.data?.pages.filter(
        (page) => page.pageType === "HOME"
      )[0]?.content;

      const id = await appDesignData?.data?.data?.pages.filter(
        (page) => page.pageType === "HOME"
      )[0]?._id;

      setPageId(id);

      setHomepageDesignData(homepageData);
    };

    getPageDesignData();
  }, [appDesignData]);

  function compareSections(a, b) {
    if (a?.position < b?.position) {
      return -1;
    }
    if (a?.position > b?.position) {
      return 1;
    }
    return 0;
  }

  const orderNewHomepage = (section, index) => {
    switch (section?.subTemplate?.sectionCode) {
      case "FULL_IMAGE_CAROUSEL":
        return <Carousel componentDesignData={section} key={section?._id} />;
      case "GRID":
        return <Grid componentDesignData={section} key={section?._id} />;

      case "AD_BANNER":
        return <AdBanner componentDesignData={section} key={section?._id} />;

      case "THREE_BANNER_GRID":
        return (
          <ThreeGridBanner componentDesignData={section} key={section?._id} />
        );

      case "OFFER_BANNER":
        return <OfferBanner componentDesignData={section} key={section?._id} />;

      case "DYNAMIC_SECTION_SLIDER":
        return (
          <DynamicSectionSlider component DesignData={section} key={section?._id} />
        );

      case "NO_MARGIN_BANNER":
        return (
          <NoMarginBanner componentDesignData={section} key={section?._id} />
        );
      case "GRID_VIEW":
        return <GridView componentDesignData={section} key={section?._id} />;
      case "TWO_BANNER_GRID":
        return (
          <TwoBannerGrid componentDesignData={section} key={section?._id} />
        );
      case "DEALS_VIEW":
        return <DealsView componentDesignData={section} key={section?._id} />;
      case "DEALS_VIEW_V2":
        return <DealsView2 componentDesignData={section} key={section?._id} />;

      case "DYNAMIC_SECTION_LIST":
        return (
          <DynamicSectionList
            componentDesignData={section}
            key={section?._id}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="homepage-new mb-5">
      {homepageDesignData?.sort(compareSections).map((section, index) => {
        return orderNewHomepage(section, index);
      })}
    </div>
  );
};

export default MainPage;
