import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Overlay, Popover, Tooltip } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { BsCaretDownFill } from "react-icons/bs";
import { FiChevronDown, FiLogOut, FiMapPin, FiMenu } from "react-icons/fi";
import { ImProfile } from "react-icons/im";
import { useHistory, useLocation } from "react-router-dom";
import newLogo from "../../assets/images/nesto-online-logo.svg";
import box from "../../assets/images/placeholder.svg";
import { AppContext } from "../../context/AppContext";
import ModalPopup from "../ModalPopup/ModalPopup";
import Sidebar from "../Sidebar/Sidebar";
import "./Mainbar.css";

import { BiBell } from "react-icons/bi";
import { HiOutlineShoppingCart } from "react-icons/hi";
import { MdFavoriteBorder } from "react-icons/md";
import useWindowSize from "../../hooks/useWindowSize";
import { AddressDetails } from "../AddressDetails/AddressDetails";
import { SearchBar } from "../SearchBar/SearchBar";

function Mainbar() {
  const cartRef = useRef(null);
  const favRef = useRef(null);
  const notiRef = useRef(null);
  const profileDropdown = useRef(null);
  const history = useHistory();
  const location = useLocation();

  const {
    isLoggedIn,
    logout,
    profile,
    setShowLogin,
    cartData,
    currentStore,
    mapbar,
    setMapbar,
    store,
    updateCurrentStore,
    activeAddress,
  } = useContext(AppContext);

  const [show, setShow] = useState(false);
  const [showAddressModal, setShowAddresssModal] = useState(false);
  const [showTT, setShowTT] = useState({});
  const [cartbar, setCartbar] = useState(false);
  const [cartTotal, setCartTotal] = useState(0);
  const [mobileMenu, setMobileMenu] = useState(false);
  const { width } = useWindowSize();

  const [selected, setSelected] = useState();

  const fixTwo = (x) => {
    let fix = Math.round(x * 100) / 100;
    return fix;
  };
  useEffect(() => {
    let mSubTotal = 0;
    let mTaxTotal = 0;
    cartData?.map((item) => {
      mSubTotal += fixTwo(item?.qty * item?.price);
      mTaxTotal += fixTwo(
        fixTwo(fixTwo(item?.qty * item?.price) * item?.tax_percentage) / 100
      );
    });

    setCartTotal(mSubTotal + mTaxTotal);
  }, [cartData]);

  useEffect(() => {
    if (cartbar) {
      window.gtag("event", "view_cart", {
        currency: "AED",
        value: parseFloat(cartTotal).toFixed(2),
        items: cartData.map((item, index) => ({
          item_id: item.sku,
          item_name: item.name,
          currency: "AED",
          index: index,
          price: item.price,
          quantity: item.qty,
        })),
      });
    }
  }, [cartbar]);

  const data = {
    time: "09:58",
    status: "Susbtitution required",
    statusinfo:
      "You have a total of 10 minutes to respond to this substitution request. Since you have allowed automatic substitutions, the store will auto pick a substitute if you fail to respond in time. ",
    image: "../../assets/images/placeholder.svg",
    order: "Order On Hold",
    date: "Thurs 28 January 2021",
    id: "#123ABC",
    price: "6 Items | 6005.00 AED",
    button: "Resolve",
  };

  useEffect(() => {
    if (store?.stores?.length > 0 && !currentStore?.store_name) {
      updateCurrentStore(store?.stores[0]);
    }
  }, []);

  useEffect(() => {
    setShowTT({
      profile: false,
    });
  }, [isLoggedIn]);

  useEffect(() => {
    let mSubTotal = 0;
    let mTaxTotal = 0;
    cartData?.map((item) => {
      mSubTotal += fixTwo(item?.qty * item?.price);
      mTaxTotal += fixTwo(
        fixTwo(fixTwo(item?.qty * item?.price) * item?.tax_percentage) / 100
      );
    });

    setCartTotal(mSubTotal + mTaxTotal);
  }, [cartData]);

  useEffect(() => {
    const urlData = window.location.href.split("/");
    const len = urlData.length;
    if (
      urlData[len - 1] === "cart" ||
      urlData[len - 1] === "wishlist" ||
      urlData[len - 1] === "notification"
    ) {
      setSelected(urlData[len - 1]);
    } else {
      setSelected("");
    }
  }, [window.location.href]);

  const handleLogout = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="react-confirm-alert-body confirm-popup-button-body">
            <h2 className="fw-700">{"Logout?"}</h2>
            <div>{"Are you sure to log out?"}</div>
            <div className="btn-group d-flex justify-content-end">
              <button
                className={"confirm-popup-button confirm-button-delete"}
                onClick={() => {
                  logout();
                  onClose();
                }}
              >
                {"Logout"}
              </button>
              <button
                className={"cancel-popup-button cancel-button-delete"}
                onClick={onClose}
              >
                No
              </button>
            </div>
          </div>
        );
      },
      closeOnClickOutside: true,
    });
  };
  return (
    <>
      <div className="mainbar container-fluid p-0">
        <div className="container p-0 d-flex justify-content-between align-items-center flex-wrap position-relative">
          <div className="d-flex align-items-center pl-4 first-menu">
            <div
              className="header-icons-wrap ham-menu mobile-menu"
              onClick={() => {
                setMobileMenu(true);
              }}
            >
              <FiMenu className="header-icons" />
            </div>
            <img
              className="new-logo c-pointer"
              src={newLogo}
              alt="logo"
              onClick={() =>
                location.pathname === "/"
                  ? window.location.reload()
                  : history.push("/")
              }
            />
          </div>
          <div
            className="address-menu"
            onClick={
              isLoggedIn
                ? () => setShowAddresssModal(true)
                : () => setShowLogin(true)
            }
          >
            <FiMapPin />
            <div className="ml-3">
              <span className="address-label">Deliver to</span>
              <div className="d-flex align-items-center">
                <span className="address-add">
                  {activeAddress
                    ? `${
                        activeAddress?.residence_type === "villa"
                          ? `${
                              activeAddress?.villa_number
                                ? activeAddress?.villa_number + ", "
                                : ""
                            }${
                              activeAddress?.street_number
                                ? activeAddress?.street_number + ", "
                                : ""
                            }${activeAddress?.area_name || ""}`
                          : `${
                              activeAddress?.building_name
                                ? activeAddress?.building_name + ", "
                                : ""
                            }${
                              activeAddress?.floor_number
                                ? activeAddress?.floor_number + ", "
                                : ""
                            }${activeAddress?.area_name || ""}`
                      }`
                    : "Add an Address"}
                </span>
                <BsCaretDownFill color="#747474" className="ml-1" />
              </div>
            </div>
          </div>
          <AddressDetails
            show={showAddressModal}
            onClose={() => setShowAddresssModal(false)}
          />
          <SearchBar />
          <div className="d-inline-flex justify-content-between align-items-center user-actions-wrap pr-4">
            {isLoggedIn && (
              <>
                <div
                  className={
                    "header-icons-wrap position-relative c-pointer " +
                    (selected === "notification" ? "selected" : "")
                  }
                  ref={notiRef}
                  onClick={() => history.push("/account/notification")}
                  onMouseEnter={() =>
                    setShowTT((showTT) => ({ ...showTT, noti: true }))
                  }
                  onMouseLeave={() =>
                    setShowTT((showTT) => ({ ...showTT, noti: false }))
                  }
                >
                  <BiBell className="header-icons" />
                </div>
                <Overlay
                  target={notiRef.current}
                  show={showTT?.noti ? showTT?.noti : false}
                  placement="bottom"
                >
                  {(props) => (
                    <Tooltip id="tooltip-noti" {...props}>
                      Notifications
                    </Tooltip>
                  )}
                </Overlay>
                <div
                  className={
                    "header-icons-wrap position-relative c-pointer " +
                    (selected === "wishlist" ? "selected" : "")
                  }
                  ref={favRef}
                  onClick={() => history.push("/account/wishlist")}
                  onMouseEnter={() =>
                    setShowTT((showTT) => ({ ...showTT, fav: true }))
                  }
                  onMouseLeave={() =>
                    setShowTT((showTT) => ({ ...showTT, fav: false }))
                  }
                >
                  <MdFavoriteBorder className="header-icons" />
                </div>
                <Overlay
                  target={favRef.current}
                  show={showTT?.fav ? showTT?.fav : false}
                  placement="bottom"
                >
                  {(props) => (
                    <Tooltip id="tooltip-fav" {...props}>
                      Wishlist
                    </Tooltip>
                  )}
                </Overlay>
                <div
                  className={
                    "header-icon-pc-mob position-relative c-pointer" +
                    (selected === " cart" ? " selected" : "") +
                    (!cartData ? " cart-loading" : "")
                  }
                  onClick={() => setCartbar(true)}
                  ref={cartRef}
                  onMouseEnter={() =>
                    setShowTT((showTT) => ({ ...showTT, cart: true }))
                  }
                  onMouseLeave={() =>
                    setShowTT((showTT) => ({ ...showTT, cart: false }))
                  }
                >
                  <HiOutlineShoppingCart className="header-icons" />
                  {cartData && cartData?.length > 0 && (
                    <div className="user-action-indicator">
                      {cartData?.length}
                    </div>
                  )}
                </div>
                <Overlay
                  target={cartRef.current}
                  show={showTT?.cart && width > 768 ? showTT?.cart : false}
                  placement="bottom"
                >
                  {(props) => (
                    <Tooltip id="tooltip-cart" {...props}>
                      My Cart
                    </Tooltip>
                  )}
                </Overlay>
                <div>
                  <button
                    className="d-flex justify-content-center align-items-center user-wrap mobile-view"
                    ref={profileDropdown}
                    onMouseEnter={() =>
                      setShowTT((showTT) => ({ ...showTT, profiles: true }))
                    }
                    onMouseLeave={() =>
                      setShowTT((showTT) => ({ ...showTT, profiles: false }))
                    }
                  >
                    {profile?.firstname}
                    <FiChevronDown
                      color="#555"
                      size="18"
                      className={
                        "ml-1 arrow-rotate" +
                        (showTT.profiles === true ? " spin180" : "")
                      }
                    />
                  </button>
                  <Overlay
                    target={profileDropdown.current}
                    show={showTT?.profiles ? showTT?.profiles : false}
                    placement="bottom"
                  >
                    <Popover
                      id="popover-basics"
                      className="d-flex flex-column account-popover"
                      onMouseEnter={() =>
                        setShowTT((showTT) => ({ ...showTT, profiles: true }))
                      }
                      onMouseLeave={() =>
                        setShowTT((showTT) => ({ ...showTT, profiles: false }))
                      }
                    >
                      <Popover.Content
                        className="fs-15 c-pointer highlight"
                        onClick={() => history.push("/account/profile")}
                      >
                        <ImProfile className="mr-2" color="#B2B2B2" /> Profile
                      </Popover.Content>

                      <Popover.Content
                        className="fs-14 c-pointer highlight"
                        onClick={handleLogout}
                      >
                        <FiLogOut className="mr-2" color="#C71712" />
                        Log Out
                      </Popover.Content>
                    </Popover>
                  </Overlay>
                </div>
              </>
            )}
            {!isLoggedIn ? (
              <button
                className="btn btn-success user-account-div d-flex justify-content-center align-items-center"
                style={{
                  background: "white",
                  color: "green",
                  border: "1.5px solid green",
                  fontSize: "14px",
                  whiteSpace: "nowrap",
                  marginLeft: "4px",
                }}
                onClick={() => {
                  setShowLogin(true);
                }}
              >
                Log In
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* MOBILE MENU OVERLAY */}
        <div className="mobile-menu-overlay">
          <Sidebar
            sidebar={mobileMenu}
            setSidebar={setMobileMenu}
            location="left"
            type="mobile-menu"
            setCartbar={setCartbar}
          />
        </div>
      </div>

      <ModalPopup show={show} setShow={setShow}>
        <SubstitutionPopUp data={data} />
      </ModalPopup>
      <Sidebar
        sidebar={cartbar}
        setSidebar={setCartbar}
        location="right"
        type="cart"
      />
      <Sidebar
        sidebar={mapbar}
        setSidebar={setMapbar}
        location="left"
        type="map"
      />
    </>
  );
}
const SubstitutionPopUp = (props) => {
  return (
    <div className="text-center p-4">
      <p className="modal-time d-inline-block">{props.data.time}</p>
      <p className="modal-title mb-2">{props.data.status}</p>
      <p className="modal-info mb-4 px-md-5">{props.data.statusinfo}</p>
      <div className="d-inline-flex mb-4">
        <div className="modal-img-wrap mr-3">
          <img className="w-100 img-fluid" src={box} alt="product"></img>
        </div>
        <div className="modal-contents text-left">
          <p className="modal-content-title mb-2">{props.data.order}</p>
          <p className="modal-content-info mb-1">{props.data.date}</p>
          <p className="modal-content-info mb-1">{props.data.id}</p>
          <p className="modal-content-info mb-1">{props.data.price}</p>
        </div>
      </div>
      <div className="modal-button-wrap">
        <button className="modal-primary-btn">{props.data.button}</button>
      </div>
    </div>
  );
};
export default Mainbar;
