import React, { useContext, useEffect, useMemo } from "react";
import { FiSearch } from "react-icons/fi";
import { useHistory, useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import "./SearchBar.css";

export const SearchBar = () => {
  const {
    setShowToast,
    setToastData,
    searchProductName,
    setSearchProductName,
  } = useContext(AppContext);
  const { search } = useLocation();
  const history = useHistory();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    const searchTerm = query.get("name");
    if (searchTerm) {
      setSearchProductName(searchTerm);
    } else {
      setSearchProductName("");
    }
  }, [query, setSearchProductName]);

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    let regVar = /^\s+$/;
    if (searchProductName.length < 3) {
      setShowToast(true);
      setToastData({
        type: "info",
        text: "Atleast 3 letter needed to search!",
      });
      return;
    }
    if (searchProductName !== "" && !regVar.test(searchProductName)) {
      window.gtag("event", "search", {
        search_term: searchProductName,
      });

      history.push(`/search?name=${searchProductName}`);
    }
  };

  return (
    <form
      className="search-wrap ignore-onclickoutside"
      onSubmit={(e) => handleSearchSubmit(e)}
    >
      <input
        className="search-wrap-input"
        type="search"
        placeholder="What are you looking for...."
        value={decodeURIComponent(searchProductName)}
        onChange={(e) => {
          setSearchProductName(e.target.value);
        }}
      />
      <button
        className="btn d-inline-block search-wrap-icon click-anim"
        type="submit"
      >
        <FiSearch className="search-wrap-svg" />
      </button>
    </form>
  );
};
