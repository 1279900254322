import { checkCustomAttributeValue } from "./checkCustomAttributeValue";

export const makeAddressData = (addr, locationData, profile) => {
  console.log("location", locationData);
  const newAddr = {
    id: addr?.entity_id,
    customer_id: profile?.id,
    firstname: addr?.firstname,
    lastname: ".",
    telephone: addr?.telephone,
    residence_type: addr?.residence_type || "building",
    building_name: addr?.building_name || "",
    floor_number: addr?.floor_number || "",
    villa_number: addr?.villa_number || "",
    street_number: addr?.street_number || "",
    street: [addr?.street],
    city: locationData?.city?.name,
    area_id: locationData?.id,
    area_name: locationData?.name,
    zms_city_id: locationData?.city?.id,
    zone_id: locationData?.zone?.id,
    zone_name: locationData?.zone?.name,
    latitude: addr?.latitude,
    longitude: addr?.longitude,
    region: {
      region_code: "UAE",
      region: "UAE",
      region_id: 43,
    },
    region_id: 43,
    custom_attributes: [],
    country_id: "AE",
  };

  newAddr.default_shipping = true;
  newAddr.default_billing = true;

  const custom_attributes = [
    "residence_type",
    "building_name",
    "floor_number",
    "villa_number",
    "street_number",
    "area_name",
    "area_id",
    "zms_city_id",
    "zone_id",
    "latitude",
    "longitude",
    "zone_name",
  ];
  let flatAddr = {
    ...newAddr,
    street: newAddr.street[0],
    mobile_number: checkCustomAttributeValue("mobile_number", profile),
    location: addr?.location || locationData?.name,
    entity_id: addr?.entity_id,
  };

  Object.keys(newAddr).forEach((k) => {
    if (custom_attributes.includes(k)) {
      newAddr.custom_attributes.push({
        attribute_code: k,
        value: newAddr[k],
      });
      delete newAddr[k];
    }
  });

  newAddr.custom_attributes.push({
    attribute_code: "mobile_number",
    value: checkCustomAttributeValue("mobile_number", profile),
  });

  newAddr.custom_attributes.push({
    attribute_code: "location",
    value: addr?.location,
  });

  return { flatAddr, newAddr };
};
