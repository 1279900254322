import { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import useWindowSize from "../../../hooks/useWindowSize";
import HomeBuilderRedirect from "../../HomeBuilderRedirect/HomeBuilderRedirect";
import "../NoMarginBanner.css";

const NoMarginItem = ({ componentDesignData }) => {
  const { width } = useWindowSize();
  const { getRedirectionUrl, getScaledFontSize } = useContext(AppContext);
  const history = useHistory();
  return (
    <div
      className="no-margin-banner"
      style={{
        backgroundImage: `linear-gradient(${componentDesignData?.subTemplate?.widget[0].details.startGradient} 100%, ${componentDesignData?.subTemplate?.widget[0].details.endGradient} 100%)`,
      }}
    >
      <div className="">
        <div className="d-flex align-items-center justify-content-between no-margin-banner_content">
          <div className="d-flex flex-column align-items-start justify-content-around">
            <h2
              className="no-margin-banner-title"
              style={{
                color:
                  componentDesignData?.subTemplate?.widget[0].details.fontColor,
                fontSize: `${getScaledFontSize(
                  componentDesignData?.subTemplate?.widget[0].details
                    .titleFontSize,
                  1.6
                )}px`,
              }}
            >
              {componentDesignData?.subTemplate?.widget[0].details?.title}
            </h2>
            {width > 768 && (
              <div className="no-margin-discount-label">
                {
                  componentDesignData?.subTemplate?.widget[0].details
                    .discountLabel
                }
              </div>
            )}
            <HomeBuilderRedirect
              widget={componentDesignData?.subTemplate?.widget[0]}
            >
              <button
                className="no-margin-banner-cta_btn"
                style={{
                  background:
                    componentDesignData?.subTemplate?.widget[0].details
                      .buttonColor,
                }}
              >
                {componentDesignData?.subTemplate?.widget[0].details.buttonName}
              </button>
            </HomeBuilderRedirect>
          </div>

          <img
            src={
              width >= 445
                ? componentDesignData?.subTemplate?.widget[0].details
                    .imageUrlWeb
                  ? componentDesignData?.subTemplate?.widget[0].details
                      .imageUrlWeb
                  : componentDesignData?.subTemplate?.widget[0].details.imageUrl
                : componentDesignData?.subTemplate?.widget[0].details.imageUrl
            }
            alt="no margin banner"
            className="NoMarginBanner__image"
          />
        </div>
      </div>
    </div>
  );
};

export default NoMarginItem;
