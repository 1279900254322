import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState } from "react";
import "./DynamicSectionSlider.css";

// NAVIGATION
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import "swiper/components/navigation/navigation.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";

import { useContext } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import { getCategoryItems } from "../../api";
import { AppContext } from "../../context/AppContext";
import useWindowSize from "../../hooks/useWindowSize";
import CartCard from "../CartCard/CartCard";

const DynamicSectionSlider = ({ componentDesignData }) => {
  const [categoryItems, setCategoryItems] = useState([]);
  const { width } = useWindowSize();
  const { getRedirectionUrl, currentStore } = useContext(AppContext);
  const [impressionList, setimpressionList] = useState([]);

  useEffect(() => {
    getCategoryItems(
      componentDesignData?.subTemplate?.details?.itemCode,
      20,
      1,
      currentStore?.store_id
    )
      .then((res) => {
        let i = 0;
        let tempData = [];
        res.map((item) => {
          if (item?.type_id === "simple") {
            tempData[i] = item;
            i++;
          } else {
            item?.configurable_child_data?.map((configItem) => {
              tempData[i] = configItem;
              i++;
            });
          }
        });
        setCategoryItems(tempData);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      <div
        className={`container bought-before-${componentDesignData?.position}`}
      >
        <div
          className="d-flex justify-content-between justify-content-md-start align-items-center"
          style={{ marginTop: "0.5rem" }}
        >
          <h1 className="homepage_title">
            {componentDesignData?.subTemplate?.details?.title}
          </h1>
          <div
            style={{
              color: "#2D9140",
              marginLeft: "0.5rem",
              marginBottom: "12px",
              fontWeight: "700",
              fontSize: "0.875rem",
              cursor: "pointer",
            }}
          >
            <Link
              to={() =>
                getRedirectionUrl(
                  componentDesignData?.subTemplate?.details?.redirectType,
                  componentDesignData?.subTemplate?.details?.itemCode
                )
              }
              style={{ all: "unset", cursor: "pointer" }}
            >
              View all <IoIosArrowForward />
            </Link>
          </div>
        </div>
        <div className="swipe-arrows">
          <div className="swipe-button-prev">
            <FiChevronLeft className="swipe-arrow" />
          </div>
          <div className="swipe-button-next">
            <FiChevronRight className="swipe-arrow" />
          </div>
          <Swiper
            spaceBetween={10}
            freeMode={true}
            slidesPerView={
              width <= 768
                ? width > 555
                  ? "3.5"
                  : width > 425
                  ? "3"
                  : width <= 320
                  ? "2"
                  : "2.5"
                : width >= 1024
                ? "7"
                : "5"
            }
            navigation={{
              nextEl: `.bought-before-${componentDesignData?.position} .swipe-button-next`,
              prevEl: `.bought-before-${componentDesignData?.position} .swipe-button-prev`,
            }}
            style={{ marginLeft: "0", marginRight: "0" }}
            observer={true}
          >
            {categoryItems.map((item, index) => (
              <SwiperSlide key={"shop-by-" + index}>
                <CartCard
                  index={index}
                  className="cart-card-container-grid"
                  item={item}
                  // from={props?.from}
                  setimpressionList={setimpressionList}
                  impressionList={impressionList}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default DynamicSectionSlider;
